import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  MapContainer, TileLayer, Marker, Popup, LayersControl
} from 'react-leaflet';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import InfoWindow from './InfoWindow';

const useStyles = makeStyles((theme) => ({
  root: {},
  leafletContainer: {
    height: '90vh',
    width: '100%'
  }
}));

const basemaps = [
  {
    name: 'ESRI',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
];

function SimpleMap({ features }) {
  const classes = useStyles();

  const [, setOpen] = useState(new Array(features.length).fill(false));

  const onChildClickCallback = (key) => {
    const index = features
      .filter((f) => f.geometry.type === 'Point')
      .findIndex((e) => e._id.$oid === key);
    setOpen((prevState) => prevState.map((item, idx) => (idx === index ? !item : item)));
  };

  return (
    <MapContainer center={[38.25, 25.15]} zoom={7} scrollWheelZoom className={classes.leafletContainer}>
      <LayersControl position="topright">
        {basemaps.map((basemap) => (
          <LayersControl.BaseLayer key={basemap.name} name={basemap.name} checked={basemap.name === 'ESRI'}>
            <TileLayer
              attribution={basemap.attribution}
              url={basemap.url}
            />
          </LayersControl.BaseLayer>
        ))}
      </LayersControl>
      <MarkerClusterGroup>
        {features
              && features.length > 0
              && features
                .filter((f) => f.geometry.type === 'Point')
                .map((point, idx) => (
                  <Marker
                    key={point._id.$oid}
                    position={[point.geometry.coordinates[1], point.geometry.coordinates[0]]}
                  >
                    <Popup maxWidth="auto" maxHeight="auto">
                      <InfoWindow point={point} setShow={() => onChildClickCallback(point._id.$oid)} />
                    </Popup>
                  </Marker>
                ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
}

export default SimpleMap;
