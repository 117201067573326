import React, { useEffect } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box, Button, Container, Link, TextField, Typography,
  Alert, AlertTitle
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'src/components/Page';
import { alertConstants, userConstants } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function PasswordRecoveryView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.get('user'));
  const alert = useSelector((state) => state.app.get('alert'));
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  useEffect(() => {
    dispatch({ type: alertConstants.CLEAR });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) return <Navigate to="/app/dashboard" />;

  return (
    <Page className={classes.root} title="Password recovery">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={(v, { setSubmitting }) => {
              dispatch({
                type: userConstants.RECOVERY_REQUEST,
                payload: { email: v.email, setSubmitting }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Recover your password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Please enter your e-mail account, and we'll send you an
                    e-mail to reset your password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset my password
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Did you remember your password?
                  {' '}
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
          {alert.get('message') && (
            <Box mt={2}>
              <Alert severity={alert.get('severity')}>
                <AlertTitle>{capitalize(alert.get('severity'))}</AlertTitle>
                {alert.get('severity') === 'error' ? (
                  <>
                    Something went wrong! —
                    {' '}
                    <strong>{alert.get('message')}</strong>
                    {' '}
                  </>
                ) : (
                  <>{alert.get('message')}</>
                )}
              </Alert>
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
}

export default PasswordRecoveryView;
