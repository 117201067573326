// @ts-nocheck
import { fromJS } from 'immutable';
import { combineReducers } from 'redux';
import {
  alertConstants,
  userConstants,
  mongoDataConstants
} from 'src/constants';
import { datacubeReducer } from './store/datacube/reducers';

const initialState = fromJS({
  user: null,
  alert: {
    severity: '',
    message: ''
  },
  users: {
    data: [],
    loading: true
  },
  mems: {
    data: [],
    size: -1,
    loading: true,
    loadingCustomQuery: false,
    customQuerySuccess: false,
    server: 'iBEC',
    alert: {
      severity: '',
      message: ''
    }
  },
  chemistry: {
    data: [],
    size: -1,
    loading: true,
    loadingCustomQuery: false,
    customQuerySuccess: false,
    alert: {
      severity: '',
      message: ''
    }
  },
  fdtool: {
    data: [],
    loading: true,
    error: null
  },
  insitu: {
    data: [],
    loading: true,
    error: null
  }
});

interface Action {
  type: string;
  payload: any;
}

let index = 0;

const appReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case alertConstants.ERROR:
    case userConstants.REGISTER_FAILURE:
    case userConstants.LOGIN_FAILURE:
    case userConstants.RECOVERY_FAILURE:
    case userConstants.RESET_FAILURE:
      return state
        .setIn(['alert', 'severity'], 'error')
        .setIn(['alert', 'message'], action.payload);
    case alertConstants.CLEAR:
      return state
        .setIn(['alert', 'severity'], '')
        .setIn(['alert', 'message'], '');
    case userConstants.REGISTER_SUCCESS:
      return state
        .setIn(['alert', 'severity'], 'success')
        .setIn(
          ['alert', 'message'],
          'User created successfully! Please wait until an administrator has approved your registration.'
        );
    case userConstants.LOGIN_SUCCESS:
      return state
        .set('user', fromJS(action.payload))
        .setIn(['alert', 'severity'], '')
        .setIn(['alert', 'message'], '');
    case userConstants.RECOVERY_SUCCESS:
    case userConstants.RESET_SUCCESS:
      return state
        .setIn(['alert', 'severity'], 'info')
        .setIn(['alert', 'message'], action.payload);
    case userConstants.LOGOUT:
      return state.set('user', null);
    case userConstants.UPDATE_SUCCESS:
      if (action.payload._id === state.get('user').get('_id')) {
        return state
          .set('user', action.payload)
          .setIn(['alert', 'severity'], '')
          .setIn(['alert', 'message'], '');
      } else {
        index = state
          .get('users')
          .get('data')
          .findIndex((i: any) => i.get('id') === action.payload.id);
        return state
          .updateIn(['users', 'data', index], (obj: any) =>
            fromJS(action.payload)
          )
          .setIn(['alert', 'severity'], '')
          .setIn(['alert', 'message'], '');
      }
    case userConstants.GETALL_SUCCESS:
      return state
        .setIn(['users', 'data'], fromJS(action.payload))
        .setIn(['users', 'loading'], false);
    case mongoDataConstants.FDTOOL_WAIT_DATA_REQUEST:
      return state.setIn(['fdtool', 'loading'], action.payload);
    case mongoDataConstants.FDTOOL_DATA_SUCCESS:
      return state.setIn(['fdtool', 'data'], fromJS(action.payload.data));
    case mongoDataConstants.FDTOOL_PHOTO_SUCCESS:
      index = state
        .get('fdtool')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state.updateIn(
        [
          'fdtool',
          'data',
          index,
          'properties',
          'areaDescriptions',
          action.payload.index,
          action.payload.key
        ],
        (str: string) => action.payload.data
      );
    case mongoDataConstants.FDTOOL_DATA_FAILURE:
    case mongoDataConstants.FDTOOL_PHOTO_FAILURE:
      return state.setIn(['fdtool', 'error'], fromJS(action.payload.message));
    case mongoDataConstants.INSITU_WAIT_DATA_REQUEST:
      return state.setIn(['insitu', 'loading'], action.payload);
    case mongoDataConstants.INSITU_DATA_SUCCESS:
      return state.setIn(['insitu', 'data'], fromJS(action.payload.data));
    case mongoDataConstants.INSITU_DATA_FAILURE:
      return state.setIn(['insitu', 'error'], fromJS(action.payload.message));
    case mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST:
      return state.setIn(['mems', 'loading'], action.payload);
    case mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST:
      return state.setIn(['chemistry', 'loading'], action.payload);
    case mongoDataConstants.MEMS_DATA_SUCCESS:
      return state
        .setIn(['mems', 'data'], fromJS(action.payload.data))
        .setIn(['mems', 'size'], action.payload.size);
    case mongoDataConstants.CHEMISTRY_DELETE_SUCCESS:
    case mongoDataConstants.CHEMISTRY_PUSH_SUCCESS:
      return state
        .setIn(['chemistry', 'alert', 'message'], action.payload)
        .setIn(['chemistry', 'alert', 'severity'], 'success')
        .setIn(['chemistry', 'loading'], true);
    case mongoDataConstants.CHEMISTRY_DELETE_FAILURE:
    case mongoDataConstants.CHEMISTRY_PUSH_FAILURE:
      return state
        .setIn(['chemistry', 'alert', 'message'], action.payload)
        .setIn(['chemistry', 'alert', 'severity'], 'error');
    case mongoDataConstants.CHEMISTRY_DATA_SUCCESS:
      return state
        .setIn(['chemistry', 'data'], fromJS(action.payload.data))
        .setIn(['chemistry', 'size'], action.payload.size);
    case mongoDataConstants.MEMS_DATA_WAIT_CUSTOM_REQUEST:
      return state.setIn(['mems', 'loadingCustomQuery'], action.payload);
    case mongoDataConstants.CHEMISTRY_DATA_WAIT_CUSTOM_REQUEST:
      return state.setIn(['chemistry', 'loadingCustomQuery'], action.payload);
    case mongoDataConstants.MEMS_DATA_CUSTOM_SUCCESS:
      return state.setIn(['mems', 'customQuerySuccess'], true);
    case mongoDataConstants.CHEMISTRY_DATA_CUSTOM_SUCCESS:
      return state.setIn(['chemistry', 'customQuerySuccess'], true);
    case mongoDataConstants.MEMS_SERVER:
      return state
        .setIn(['mems', 'server'], action.payload)
        .setIn(['mems', 'loading'], true)
        .setIn(['mems', 'alert', 'severity'], '')
        .setIn(['mems', 'alert', 'message'], '');
    case mongoDataConstants.MEMS_DATA_WAIT_PHOTO_REQUEST:
      index = state
        .get('mems')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state.updateIn(['mems', 'data', index, 'photo'], (obj: any) =>
        obj.set('loading', true)
      );
    case mongoDataConstants.CHEMISTRY_DATA_WAIT_PHOTO_REQUEST:
      index = state
        .get('chemistry')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state.updateIn(['chemistry', 'data', index, 'photo'], (obj: any) =>
        obj.set('loading', true)
      );
    case mongoDataConstants.MEMS_DATA_PHOTO_SUCCESS:
      index = state
        .get('mems')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state.updateIn(['mems', 'data', index, 'photo'], (obj: any) =>
        obj.set('base64String', action.payload.img).set('loading', false)
      );
    case mongoDataConstants.CHEMISTRY_DATA_PHOTO_SUCCESS:
      index = state
        .get('chemistry')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state.updateIn(['chemistry', 'data', index, 'photo'], (obj: any) =>
        obj.set('base64String', action.payload.img).set('loading', false)
      );
    case mongoDataConstants.MEMS_DATA_PHOTO_FAILURE:
      index = state
        .get('mems')
        .get('data')
        .findIndex((i: any) => i.get('_id').get('$oid') === action.payload.id);
      return state
        .updateIn(['mems', 'data', index, 'photo'], (obj: any) =>
          obj.set('loading', false)
        )
        .setIn(['mems', 'alert', 'severity'], 'warning')
        .setIn(
          ['mems', 'alert', 'message'],
          'Failed to load photo. Please try again.'
        );
    case mongoDataConstants.MEMS_PUSH_CF_SUCCESS:
      return state
        .setIn(['mems', 'alert', 'severity'], 'success')
        .setIn(['mems', 'alert', 'message'], action.payload);
    case mongoDataConstants.MEMS_PUSH_CF_FAILURE:
    case mongoDataConstants.MEMS_DATA_FAILURE:
      return state
        .setIn(['mems', 'alert', 'severity'], 'error')
        .setIn(['mems', 'alert', 'message'], action.payload);
    case mongoDataConstants.MEMS_CUSTOM_ALERT:
      return state
        .setIn(['mems', 'alert', 'severity'], action.payload.severity)
        .setIn(['mems', 'alert', 'message'], action.payload.message);
    case mongoDataConstants.CHEMISTRY_CUSTOM_ALERT:
      return state
        .setIn(['chemistry', 'alert', 'severity'], action.payload.severity)
        .setIn(['chemistry', 'alert', 'message'], action.payload.message);
    case mongoDataConstants.CHEMISTRY_CLEAR_ALERT:
      return state
        .setIn(['chemistry', 'alert', 'severity'], '')
        .setIn(['chemistry', 'alert', 'message'], '');
    case mongoDataConstants.MEMS_CLEAR_ALERT:
      return state
        .setIn(['mems', 'alert', 'severity'], '')
        .setIn(['mems', 'alert', 'message'], '');
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  app: appReducer,
  datacube: datacubeReducer
});

export type RootState = ReturnType<typeof rootReducer>;
