import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AvatarDialog from './AvatarDialog';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

function Profile({ className, ...rest }) {
  const classes = useStyles();
  const user = useSelector((state) => {
    return state.app.get('user');
  });
  const [open, setOpen] = React.useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar
            className={classes.avatar}
            src={`data:image/${user.get('avatar_ext')};base64,${user.get('avatar_base64')}`}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {user.get('name')}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.get('role')}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={() => setOpen(true)}
        >
          Upload picture
        </Button>
        <AvatarDialog open={open} setOpen={setOpen} />
      </CardActions>
    </Card>
  );
}

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
