import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: colors.red[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  typography,
  shadows
});

export default theme;
