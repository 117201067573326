import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import Page from 'src/components/Page';
import { mongoDataConstants } from 'src/constants';
import SimpleMap from './SimpleMap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function GoogleMapView() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const fdtool = useSelector((state) => {
    return state.app.get('fdtool');
  });
  const insitu = useSelector((state) => {
    return state.app.get('insitu');
  });

  useEffect(() => {
    if (fdtool.get('loading')) {
      dispatch({
        type: mongoDataConstants.FDTOOL_MAKE_DATA_REQUEST,
      });
      dispatch({
        type: mongoDataConstants.INSITU_MAKE_DATA_REQUEST,
      });
    }
  }, [dispatch, fdtool]);

  return (
    <Page className={classes.root} title="FDTool map view">
      {!fdtool.get('loading') && !insitu.get('loading') && (fdtool.get('data').size > 0 || insitu.get('data').size > 0) && (
        <SimpleMap features={fdtool.get('data').toJS().concat(insitu.get('data').toJS())} />
      )}
      {!fdtool.get('loading') && !insitu.get('loading') && (fdtool.get('data').size + insitu.get('data').size === 0) && (
        <Box p={5}>
          <Alert severity="warning">
            <AlertTitle>Προειδοποίηση</AlertTitle>
            Δεν έχετε υποβάλει μετρήσεις. Ο χάρτης θα εμφανιστεί μόνο εφόσον
            υπάρχουν δεδομένα.
          </Alert>
        </Box>
      )}
      <Backdrop className={classes.backdrop} open={fdtool.get('loading')}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
}

export default GoogleMapView;
