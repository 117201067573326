import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import SpectraListView from 'src/views/mems/SpectraListView';
import GoogleMapView from 'src/views/fdtool/Leaflet';
import DatacubeAdminView from 'src/views/datacube/AdminView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import GeodataView from 'src/views/geodata';
import LoginView from 'src/views/auth/LoginView';
import RegisterView from 'src/views/auth/RegisterView';
import PasswordRecoveryView from 'src/views/auth/PasswordRecoveryView';
import ResetView from 'src/views/auth/ResetView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SettingsView from 'src/views/settings/SettingsView';
import ChemistryListView from 'src/views/chemistry/ListView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'mems', element: <SpectraListView /> },
      { path: 'chemistry', element: <ChemistryListView /> },
      { path: 'fdtool', element: <GoogleMapView /> },
      { path: 'datacube', element: <DatacubeAdminView /> },
      { path: 'geodata', element: <GeodataView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'recovery', element: <PasswordRecoveryView /> },
      { path: 'reset/:token', element: <ResetView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
