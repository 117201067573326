import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Checkbox,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { mongoDataConstants } from 'src/constants';
import Spectrum from './Values';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function Results({
  className,
  SelectedIds,
  setSelectedIds,
  open,
  setOpen,
  ...rest
}) {
  const classes = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.get('user'));
  const chemistry = useSelector((state) => {
    return state.app.get('chemistry');
  });

  const handleSetOpen = (event, index) => {
    event.persist();
    if (
      !(
        event.target.attributes
        && event.target.attributes.type
        && event.target.attributes.type.nodeValue === 'checkbox'
      )
    ) {
      setOpen((prevState) => prevState.map((item, idx) => (idx === index ? !item : item)));
    } // else it's a click on the checkbox, don't toggle the table row
  };

  useEffect(() => {
    if (chemistry.get('loading')) {
      dispatch({
        type: mongoDataConstants.CHEMISTRY_DATA_MAKE_DATA_REQUEST,
        payload: {
          page: page + 1,
          pagesize: limit
        },
        collection: 'chemistry'
      });
    }
  }, [dispatch, chemistry, user, limit, page]);

  const handleSelectAll = (event) => {
    let newSelectedIds;

    if (event.target.checked) {
      newSelectedIds = chemistry
        .get('data')
        .toJS()
        .map((datum) => datum._id.$oid);
    } else {
      newSelectedIds = [];
    }

    setSelectedIds(newSelectedIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = SelectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(SelectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(SelectedIds.slice(1));
    } else if (selectedIndex === SelectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(SelectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        SelectedIds.slice(0, selectedIndex),
        SelectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectedIds(newSelectedIds);
  };

  const handleLimitChange = (event) => {
    const thisPageStartsFrom = page * limit;
    const futurePage = Math.floor(thisPageStartsFrom / event.target.value);
    setLimit(event.target.value);
    setPage(futurePage);
    dispatch({
      type: mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST,
      payload: true,
      collection: 'mems.chemistry'
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setOpen((prevState) => prevState.map((item, idx) => false)); // Collapse all when changing page
    if (!chemistry.get('customQuerySuccess')) {
      dispatch({
        type: mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST,
        payload: true,
        collection: 'mems.chemistry'
      });
    }
  };

  const startIndex = chemistry && chemistry.get('customQuerySuccess') ? page * limit : 0;

  return chemistry && chemistry.get('data') && chemistry.get('data').size > 0 ? (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={SelectedIds.length === chemistry.get('data').size}
                    color="primary"
                    indeterminate={
                      SelectedIds.length > 0
                      && SelectedIds.length < chemistry.get('data').size
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Sample ID</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Sampling Date</TableCell>
                <TableCell>Lat</TableCell>
                <TableCell>Lon</TableCell>
                <TableCell>Crop</TableCell>
                <TableCell>Depth</TableCell>
                <TableCell>Disturbed</TableCell>
                <TableCell>More</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chemistry
                .get('data')
                .toJS()
                .slice(startIndex, startIndex + limit)
                .map((datum, idx) => (
                  <React.Fragment key={`${datum._id.$oid}-parent`}>
                    <TableRow
                      hover
                      key={datum._id.$oid}
                      selected={SelectedIds.indexOf(datum._id.$oid) !== -1}
                      onClick={(e) => handleSetOpen(e, idx)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={SelectedIds.indexOf(datum._id.$oid) !== -1}
                          onChange={(event) => handleSelectOne(event, datum._id.$oid)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{datum.Sample_ID}</TableCell>
                      <TableCell>{datum.Project}</TableCell>
                      <TableCell>{datum.Sampling_Date}</TableCell>
                      <TableCell>{datum.Lat}</TableCell>
                      <TableCell>{datum.Lon}</TableCell>
                      <TableCell>{datum.Crop}</TableCell>
                      <TableCell>{datum.Depth}</TableCell>
                      <TableCell>{datum.Disturbed}</TableCell>
                      <TableCell>
                        {open[idx] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow key={`${datum._id.$oid}-details`}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={8}
                      >
                        <Collapse in={open[idx]} timeout="auto" unmountOnExit>
                          <Spectrum object={datum} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={chemistry.get('size')}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
      />
    </Card>
  ) : null;
}

Results.propTypes = {
  className: PropTypes.string,
  SelectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  open: PropTypes.array,
  setOpen: PropTypes.func
};

export default Results;
