import React from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { colors, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { extractTileFromStr } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    minHeight: '500px'
  },
  map: {
    height: '500px'
  }
}));

function DatasetsChart() {
  const classes = useStyles();
  const theme = useTheme();
  const datasets = useSelector((state) => state.datacube.get('datasets'));
  const [tile, setTile] = React.useState({});

  // Group the tiles and extract the dates
  const tiles = datasets.toJS().reduce((r, x) => {
    const thisTile = extractTileFromStr(x.locations[0]);
    const dateStr = moment(new Date(x.fields.time.begin)).format('MM/YYYY');
    if (thisTile in r) {
      if (dateStr in r[thisTile]) {
        r[thisTile][dateStr]++;
      } else {
        r[thisTile][dateStr] = 1;
      }
    } else {
      r[thisTile] = {
        [dateStr]: 1
      };
      new Date(x.fields.time.begin);
    }
    return r;
  }, Object.create({}));
  const tilesArr = Object.keys(tiles).map((key) => ({
    title: key,
    dates: Object.keys(tiles[key])
      .map((thisDate) => ({
        date: thisDate,
        count: tiles[key][thisDate]
      }))
      .sort(
        (a, b) => moment(a.date, 'MM/YYYY').valueOf()
          - moment(b.date, 'MM/YYYY').valueOf()
      )
  })).sort((a, b) => a.title.localeCompare(b.title));

  if (tilesArr.length > 0) {
    // Generate chart-js data and options
    const data = {
      datasets: [
        {
          barPercentage: 0.4,
          barThickness: 10,
          maxBarThickness: 20,
          borderRadius: 20,
          backgroundColor: colors.indigo[500],
          data: tile && tile.dates ? tile.dates.map((x) => x.count) : [],
          label: tile && tile.title ? tile.title : 'undefined'
        }
      ],
      labels: tile && tile.dates ? tile.dates.map((x) => x.date) : []
    };
    const options = {
      animation: false,
      layout: { padding: 0 },
      legend: { display: false },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            precision: 0
          },
          border: {
            dash: [2],
            dashOffset: [2],
          },
          grid: {
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      },
      tooltips: {
        backgroundColor: theme.palette.background.default,
        bodyFontColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerFontColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleFontColor: theme.palette.text.primary
      }
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          title="Chart of indexed datasets per tile"
          action={(
            <Autocomplete
              id="combo-box-tiles"
              onChange={(event, value) => setTile(value || {})}
              isOptionEqualToValue={(option, v) => option.title === v.title}
              getOptionLabel={(option) => option.title}
              options={tilesArr}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select tile" />
              )}
            />
          )}
        />
        <Divider />
        <CardContent className={classes.container}>
          <Box height={400} position="relative">
            <Bar data={data} options={options} />
          </Box>
        </CardContent>
      </Card>
    );
  }
  return null;
}

DatasetsChart.propTypes = {};

export default DatasetsChart;
