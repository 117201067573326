import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import logger from 'redux-logger'

import { rootReducer } from './reducers';
import mySaga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// const enhancers = [applyMiddleware(sagaMiddleware), applyMiddleware(logger)];
const enhancers = [applyMiddleware(sagaMiddleware)];
const store = createStore(rootReducer, compose(...enhancers));

// then run the saga
sagaMiddleware.run(mySaga);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
