import {
  call, delay, put, race, select
} from 'redux-saga/effects';
import { mongoDataConstants } from 'src/constants';
import * as selectors from '../selectors';

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.error('401');
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};

const getHeaders = () => {
  return {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
    'Content-Type': 'application/json'
  };
};

const getRequest = async (query) => {
  return fetch(`./api/restheart/${query}`, {
    method: 'GET',
    headers: getHeaders()
  }).then(handleResponse);
};

const postRequest = async (query, body) => {
  return fetch(`./api/restheart/${query}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body),
  }).then(handleResponse);
};

export function* getMEMSdata({ payload }) {
  const server = yield select(selectors.server);

  yield put({
    type: mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, `mems/${server}/mems/${payload.page}/${payload.pagesize}`),
      connectionTimeout: delay(30000)
    });

    const racedSize = yield race({
      posted: call(getRequest, `mems/${server}/mems/size`),
      connectionTimeout: delay(30000)
    });

    yield put({
      type: mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST,
      payload: false
    });
    if (raced.posted && racedSize.posted) {
      yield put({
        type: mongoDataConstants.MEMS_DATA_SUCCESS,
        payload: { data: raced.posted, size: racedSize.posted._size }
      });
    } else {
      yield put({
        type: mongoDataConstants.MEMS_DATA_FAILURE,
        payload: 'Connection timed out'
      });
    }
  } catch (error) {
    yield put({
      type: mongoDataConstants.MEMS_DATA_FAILURE,
      payload: {
        message: `An unexpected error ocurred: ${error}`
      }
    });
  } finally {
    yield put({
      type: mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST,
      payload: false
    });
  }
}

export function* getMEMScustom({ payload }) {
  const server = yield select(selectors.server);

  yield put({
    type: mongoDataConstants.MEMS_DATA_WAIT_CUSTOM_REQUEST,
    payload: true
  });

  const raced = yield race({
    posted: call(getRequest, `mems/${server}/mems/1/1000/custom?filter=${payload.searchString}`),
    connectionTimeout: delay(30000)
  });

  yield put({
    type: mongoDataConstants.MEMS_DATA_WAIT_CUSTOM_REQUEST,
    payload: false
  });

  if (raced.posted) {
    yield put({
      type: mongoDataConstants.MEMS_DATA_SUCCESS,
      payload: { data: raced.posted, size: raced.posted.length }
    });
    yield put({ type: mongoDataConstants.MEMS_DATA_CUSTOM_SUCCESS });
  } else {
    yield put({ type: mongoDataConstants.MEMS_DATA_CUSTOM_FAILURE });
  }
}

export function* getChemistryCustom({ payload }) {
  yield put({
    type: mongoDataConstants.CHEMISTRY_DATA_WAIT_CUSTOM_REQUEST,
    payload: true
  });

  const raced = yield race({
    posted: call(getRequest, `iBEC/chemistry/1/1000/custom?filter=${payload.searchString}`),
    connectionTimeout: delay(30000)
  });

  yield put({
    type: mongoDataConstants.CHEMISTRY_DATA_WAIT_CUSTOM_REQUEST,
    payload: false
  });

  if (raced.posted) {
    yield put({
      type: mongoDataConstants.CHEMISTRY_DATA_SUCCESS,
      payload: { data: raced.posted, size: raced.posted.length }
    });
    yield put({ type: mongoDataConstants.CHEMISTRY_DATA_CUSTOM_SUCCESS });
  } else {
    yield put({ type: mongoDataConstants.MEMS_DATA_CUSTOM_FAILURE });
  }
}

export function* getMEMSphoto({ payload, collection }) {
  let server = yield select(selectors.server);

  if (collection === 'mems.chemistry') {
    server = 'iBEC';

    yield put({
      type: mongoDataConstants.CHEMISTRY_DATA_WAIT_PHOTO_REQUEST,
      payload: {
        id: payload.id
      }
    });
  } else {
    yield put({
      type: mongoDataConstants.MEMS_DATA_WAIT_PHOTO_REQUEST,
      payload: {
        id: payload.id
      }
    });
  }

  const raced = yield race({
    photo: call(getRequest, `mems/${server}/${collection}/doc/${payload.url}/photo/`),
    connectionTimeout: delay(30000)
  });

  if (raced.photo) {
    if (collection === 'mems.chemistry') {
      yield put({
        type: mongoDataConstants.CHEMISTRY_DATA_PHOTO_SUCCESS,
        payload: { id: payload.id, img: raced.photo }
      });
    } else {
      yield put({
        type: mongoDataConstants.MEMS_DATA_PHOTO_SUCCESS,
        payload: { id: payload.id, img: raced.photo }
      });
    }
  } else {
    yield put({
      type: mongoDataConstants.MEMS_DATA_PHOTO_FAILURE,
      payload: { id: payload.id }
    });
  }
}

export function* postMEMScfs({ payload, setOpen }) {
  const server = yield select(selectors.server);

  yield put({
    type: mongoDataConstants.MEMS_WAIT_CF_REQUEST,
    payload: true
  });
  try {
    const raced = yield race({
      posted: call(postRequest, `mems/${server}/correctionfactors`, payload),
      connectionTimeout: delay(15000)
    });

    if (!raced.connectionTimeout) {
      yield put({
        type: mongoDataConstants.MEMS_PUSH_CF_SUCCESS,
        payload: 'Correction factors sumbitted successfully.'
      });
    } else {
      yield put({
        type: mongoDataConstants.MEMS_PUSH_CF_FAILURE,
        payload: 'Connection timed out while submitting correction factors. Please try again.'
      });
    }
  } catch (error) {
    yield put({
      type: mongoDataConstants.MEMS_DATA_FAILURE,
      payload: `An unexpected error ocurred: ${error}`
    });
  } finally {
    yield put({
      type: mongoDataConstants.MEMS_WAIT_CF_REQUEST,
      payload: false
    });
    setOpen(false);
  }
}

export function* postChemistry({ payload, setOpen }) {
  yield put({
    type: mongoDataConstants.CHEMISTRY_WAIT_REQUEST,
    payload: true
  });
  try {
    const raced = yield race({
      posted: call(postRequest, 'iBEC/chemistry', payload),
      connectionTimeout: delay(15000)
    });

    if (!raced.connectionTimeout) {
      yield put({
        type: mongoDataConstants.CHEMISTRY_PUSH_SUCCESS,
        payload: 'Chemistry values sumbitted successfully.'
      });
    } else {
      yield put({
        type: mongoDataConstants.CHEMISTRY_PUSH_FAILURE,
        payload: 'Connection timed out while submitting correction factors. Please try again.'
      });
    }
  } catch (error) {
    yield put({
      type: mongoDataConstants.MEMS_DATA_FAILURE,
      payload: `An unexpected error ocurred: ${error}`
    });
  } finally {
    yield put({
      type: mongoDataConstants.CHEMISTRY_WAIT_REQUEST,
      payload: false
    });
    setOpen(false);
  }
}

export function* getChemistryData({ payload }) {
  yield put({
    type: mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, `iBEC/chemistry/${payload.page}/${payload.pagesize}`),
      connectionTimeout: delay(30000)
    });

    const racedSize = yield race({
      posted: call(getRequest, 'iBEC/chemistry/size'),
      connectionTimeout: delay(30000)
    });

    yield put({
      type: mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST,
      payload: false
    });

    if (raced.posted && racedSize.posted) {
      yield put({
        type: mongoDataConstants.CHEMISTRY_DATA_SUCCESS,
        payload: { data: raced.posted, size: racedSize.posted._size }
      });
    } else {
      yield put({
        type: mongoDataConstants.CHEMISTRY_DATA_FAILURE,
        payload: 'Connection timed out'
      });
    }
  } catch (error) {
    yield put({
      type: mongoDataConstants.CHEMISTRY_DATA_FAILURE,
      payload: {
        message: `An unexpected error ocurred: ${error}`
      }
    });
  } finally {
    yield put({
      type: mongoDataConstants.CHEMISTRY_DATA_WAIT_DATA_REQUEST,
      payload: false
    });
  }
}

export function* postChemistryDelete({ payload }) {
  yield put({
    type: mongoDataConstants.CHEMISTRY_DELETE_WAIT_REQUEST,
    payload: true
  });
  try {
    const raced = yield race({
      posted: call(postRequest, 'iBEC/chemistry/delete', payload),
      connectionTimeout: delay(15000)
    });

    yield put({
      type: mongoDataConstants.CHEMISTRY_DELETE_SUCCESS,
      payload: 'Deleted successfully.'
    });
  } catch (error) {
    yield put({
      type: mongoDataConstants.CHEMISTRY_DELETE_FAILURE,
      payload: `An unexpected error ocurred: ${error}`
    });
  } finally {
    yield put({
      type: mongoDataConstants.CHEMISTRY_DELETE_WAIT_REQUEST,
      payload: false
    });
  }
}

export function* getFDtoolPoints() {
  yield put({
    type: mongoDataConstants.FDTOOL_WAIT_DATA_REQUEST,
    payload: true
  });

  const raced = yield race({
    posted: call(getRequest, 'fdtool/all'),
    connectionTimeout: delay(15000)
  });

  yield put({
    type: mongoDataConstants.FDTOOL_WAIT_DATA_REQUEST,
    payload: false
  });

  if (raced.posted) {
    yield put({
      type: mongoDataConstants.FDTOOL_DATA_SUCCESS,
      payload: { data: raced.posted }
    });
  } else {
    yield put({
      type: mongoDataConstants.FDTOOL_DATA_FAILURE,
      payload: {
        message: 'Connection timed out'
      }
    });
  }
}

export function* getFDtoolPhoto({ payload }) {
  yield put({
    type: mongoDataConstants.FDTOOL_WAIT_PHOTO_REQUEST,
    payload: true
  });

  const raced = yield race({
    posted: call(getRequest, `fdtool/image/${payload.path}`),
    connectionTimeout: delay(15000)
  });

  yield put({
    type: mongoDataConstants.FDTOOL_WAIT_PHOTO_REQUEST,
    payload: false
  });

  if (raced.posted) {
    yield put({
      type: mongoDataConstants.FDTOOL_PHOTO_SUCCESS,
      payload: {
        id: payload.id,
        index: payload.index,
        key: payload.key,
        data: raced.posted
      }
    });
  } else {
    yield put({ type: mongoDataConstants.FDTOOL_PHOTO_FAILURE });
  }
}

export function* getInsitu() {
  yield put({
    type: mongoDataConstants.INSITU_WAIT_DATA_REQUEST,
    payload: true
  });

  const raced = yield race({
    posted: call(getRequest, 'insitu'),
    connectionTimeout: delay(15000)
  });

  yield put({
    type: mongoDataConstants.INSITU_WAIT_DATA_REQUEST,
    payload: false
  });

  if (raced.posted) {
    yield put({
      type: mongoDataConstants.INSITU_DATA_SUCCESS,
      payload: { data: raced.posted }
    });
  } else {
    yield put({
      type: mongoDataConstants.INSITU_DATA_FAILURE,
      payload: {
        message: 'Connection timed out'
      }
    });
  }
}
