import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search as SearchIcon } from 'react-feather';
import { CsvBuilder } from 'filefy';
import JSZip from 'jszip';
import { mongoDataConstants } from 'src/constants';
import StandardizationModal from './StandardizationModal';

const useStyles = makeStyles((theme) => ({
  documentationLink: {
    marginLeft: theme.spacing(2)
  },
  toolbarButton: {
    marginRight: theme.spacing(2)
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  }
}));

function Toolbar({
  className, SelectedIds, setOpen, ...rest
}) {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [isStandardizationModalOpen, setStandardizationModalOpen] = useState(
    false
  );

  const dispatch = useDispatch();
  const mems = useSelector((state) => {
    return state.app.get('mems');
  });

  const search = () => {
    dispatch({
      type: mongoDataConstants.MEMS_DATA_MAKE_CUSTOM_REQUEST,
      payload: { searchString: searchString.replace(/"/g, "'") },
      collection: 'mems'
    });
  };

  const getArrayFromDatum = (datum, standardized) => {
    return [
      datum._id.$oid,
      datum.sample && datum.sample.value ? datum.sample.value : 'default',
      datum.user.email,
      datum.location.coords.latitude,
      datum.location.coords.longitude,
      datum.date.ISO,
      datum.date.epoch,
      datum.device.name,
      standardized
    ].concat(
      standardized
        ? datum.scans.sample.standardizedReflectance
        : datum.scans.sample.reflectance
    );
  };

  const exportOneCsv = (header, data) => {
    const builder = new CsvBuilder('reflectance.csv');
    const wavelengths = new Array(data[0].scans.white.spectrum.length);
    const root = data[0].scans.white.parameters.MeasSettings.Wavelengths;
    for (let i = 0; i < wavelengths.length; ++i) {
      wavelengths[i] = root.MinWL + i * root.Step;
    }
    builder.setDelimeter(',').setColumns(header.concat(wavelengths));
    for (const datum of data) {
      builder.addRow(getArrayFromDatum(datum, false));
      if (datum.scans.sample.standardizedReflectance) {
        builder.addRow(getArrayFromDatum(datum, true));
      }
    }
    builder.exportFile();
  };

  const exportMultipleCsv = (header, data) => {
    const zip = new JSZip();
    Object.keys(data).forEach((key) => {
      const wavelengths = new Array(data[key][0].scans.white.spectrum.length);
      const root = data[key][0].scans.white.parameters.MeasSettings.Wavelengths;
      for (let i = 0; i < wavelengths.length; ++i) {
        wavelengths[i] = root.MinWL + i * root.Step;
      }
      const dataArray = [header.concat(wavelengths)];
      for (const datum of data[key]) {
        dataArray.push(getArrayFromDatum(datum, false));
        if (datum.scans.sample.standardizedReflectance) {
          dataArray.push(getArrayFromDatum(datum, true));
        }
      }
      zip.file(`${key}.csv`, dataArray.join('\r\n'));
    });

    zip
      .generateAsync({
        type: 'base64'
      })
      .then((content) => {
        const link = document.createElement('a');
        link.href = `data:application/zip;base64,${content}`;
        link.download = `spectra-${new Date().toISOString()}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const exportData = () => {
    const header = [
      'id',
      'sampleName',
      'user',
      'lat',
      'lon',
      'date',
      'epoch',
      'instrument',
      'standardized'
    ];
    const data = mems
      .get('data')
      .toJS()
      .filter((datum) => SelectedIds.includes(datum._id.$oid));
    const grouped = data.reduce((acc, x) => {
      const root = x.scans.white.parameters.MeasSettings.Wavelengths;
      const key = [root.MinWL, root.MaxWL, root.Step].join('_');
      (acc[key] = acc[key] || []).push(x);
      return acc;
    }, {});
    if (Object.keys(grouped).length === 1) {
      exportOneCsv(header, grouped[Object.keys(grouped)[0]]);
    } else if (Object.keys(grouped).length > 1) {
      exportMultipleCsv(header, grouped);
    }
  };

  const toggleCollapse = () => {
    setOpen((prevState) => prevState.map((item) => !item));
  };

  const collapseAll = () => {
    setOpen((prevState) => prevState.map((item) => false));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.toolbarButton} onClick={collapseAll}>
          Collapse all
        </Button>
        <Button className={classes.toolbarButton} onClick={toggleCollapse}>
          Toggle collapse
        </Button>
        <Button
          className={classes.toolbarButton}
          color="primary"
          variant="contained"
          onClick={exportData}
          disabled={SelectedIds.length === 0}
        >
          Export
        </Button>
        <Button variant="contained" onClick={() => setStandardizationModalOpen(true)}>
          Standardization
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={600} display="flex" alignItems="center">
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search data with user's email"
                variant="outlined"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <Button className={classes.searchButton} onClick={search}>
                Search
              </Button>
              <Typography>
                <Link
                  href="https://restheart.org/docs/read-docs/"
                  className={classes.documentationLink}
                  variant="inherit"
                  underline="hover"
                >
                  Documentation
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <StandardizationModal
        open={isStandardizationModalOpen}
        setOpen={setStandardizationModalOpen}
      />
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  SelectedIds: PropTypes.array,
  setOpen: PropTypes.func
};

export default Toolbar;
