import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { SimpleFileUpload } from 'src/utils/SimpleFileUpload';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { mongoDataConstants } from 'src/constants';
import config from '../../../config.json';

const useStyles = makeStyles(() => ({
  form: {
    paddingLeft: '24px'
  },
  field: {
    marginBottom: '15px'
  }
}));

const MAX_FILE_SIZE = 2097152; // 2 MB

const schema = Yup.object().shape({
  file: Yup.mixed()
    .required('Required')
    .test(
      'file',
      'File size should be less than 2 MB',
      (value) => value == null || value.size < MAX_FILE_SIZE
    )
});

function ChemistryModal({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const displayWarning = (message) => {
    dispatch({
      type: mongoDataConstants.CHEMISTRY_CUSTOM_ALERT,
      payload: {
        severity: 'warning',
        message
      }
    });
    handleClose();
  };

  const checkParsedObject = (obj, which) => {
    const isValid = obj && obj.data && obj.errors && obj.errors.length === 0;
    if (!isValid) {
      displayWarning(
        `An error occurred while parsing the ${
          which
        } csv file. Please check that it is valid.`
      );
    }
    return isValid;
  };

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const checkAndProcess = (file) => {
    if (checkParsedObject(file, 'file')) {
      if (!arraysEqual(file.data[0], config.chemistry)) {
        displayWarning('The header of the CSV file are wrong.');
        return;
      }

      const data = file.data.slice(1).map((line) => {
        const properties = {};
        const values = {};
        for (let i = 0; i < file.data[0].length; i++) {
          if (line[i] !== '') {
            if (file.data[0][i].includes('.')) {
              const val = file.data[0][i].split('.');
              if (!values.hasOwnProperty(val[0])) {
                values[val[0]] = {};
              }
              values[val[0]][val[1]] = line[i];
            } else {
              properties[file.data[0][i]] = line[i];
            }
          }
        }
        return {
          ...properties,
          values
        };
      });

      dispatch({
        type: mongoDataConstants.CHEMISTRY_PUSH_REQUEST,
        payload: data,
        setOpen
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={{
          file: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          const reader = new FileReader();
          reader.readAsText(values.file);
          reader.onload = () => {
            const csvFile = Papa.parse(reader.result, { skipEmptyLines: true });
            checkAndProcess(csvFile);
          };
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <DialogTitle id="form-dialog-title">
              Add chemistry reference file
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This form allows you to upload the chemistry measurements.
              </DialogContentText>
            </DialogContent>
            <Form className={classes.form}>
              <div className={classes.field}>
                <Field
                  component={SimpleFileUpload}
                  name="file"
                  accept=".csv"
                />
              </div>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

ChemistryModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default ChemistryModal;
