import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  TileLayer,
  FeatureGroup,
  Popup,
  Rectangle,
  Polygon,
  Tooltip
} from 'react-leaflet';
import { leafletConstants } from 'src/constants';
import { extractTileFromStr } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    minHeight: '500px'
  },
  map: {
    height: '500px'
  }
}));

function DatasetsMap() {
  const classes = useStyles();
  const datasets = useSelector((state) => state.datacube.get('datasets'));
  const areas = useSelector((state) => state.datacube.get('areas'));

  if (datasets && datasets.size > 0) {
    const tiles = datasets.toJS().reduce((r, x) => {
      const thisTile = extractTileFromStr(x.locations[0]);
      console.log(x);
      if (thisTile in r) {
        r[thisTile].count++;
      } else {
        r[thisTile] = {
          count: 1,
          rectangle: [
            [x.fields.lat.begin, x.fields.lon.begin],
            [x.fields.lat.end, x.fields.lon.end]
          ]
        };
      }
      return r;
    }, Object.create({}));
    const colors = [
      '#1b9e77',
      '#d95f02',
      '#7570b3',
      '#e7298a',
      '#66a61e',
      '#e6ab02'
    ];
    const center = [40, 30];
    return (
      <Card className={classes.root}>
        <CardHeader title="Map of indexed datasets and monitored areas" />
        <Divider />
        <CardContent className={classes.container}>
          <MapContainer
            className={classes.map}
            center={center}
            zoom={5}
            scrollWheelZoom={false}
          >
            <LayersControl position="topright">
              {leafletConstants.BASEMAPS.map((basemap) => (
                <LayersControl.BaseLayer
                  key={basemap.name}
                  name={basemap.name}
                  checked={basemap.name === 'OpenStreetMap'}
                >
                  <TileLayer
                    attribution={basemap.attribution}
                    url={basemap.url}
                  />
                </LayersControl.BaseLayer>
              ))}
              <LayersControl.Overlay checked name="indexed tiles">
                <LayerGroup>
                  {Object.keys(tiles).map((x, idx) => {
                    const colorOptions = { color: colors[idx % colors.length] };
                    return (
                      <FeatureGroup key={idx} pathOptions={colorOptions}>
                        <Popup>
                          Tile
                          {' '}
                          {x}
                          <br />
                          Count:
                          {' '}
                          {tiles[x].count}
                        </Popup>
                        <Rectangle bounds={tiles[x].rectangle} />
                      </FeatureGroup>
                    );
                  })}
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay checked name="monitored areas">
                <LayerGroup>
                  {areas
                    && areas.size > 0
                    && areas
                      .toJS()
                      .filter((x) => x.area.includes('POINT'))
                      .map((x, idx) => {
                        const res = Array.from(
                          x.area.match(/POINT[ ]*\(([\-\d\.]+) ([\-\d\.]+)\)/)
                        );
                        const position = [res[2], res[1]];
                        return (
                          <Marker key={idx} position={position}>
                            <Tooltip direction="auto">
                              {x.description}
                              ,
                              {x.area}
                            </Tooltip>
                          </Marker>
                        );
                      })}
                  {areas
                    && areas.size > 0
                    && areas
                      .toJS()
                      .filter((x) => x.area.includes('POLYGON'))
                      .map((x, idx) => {
                        const res = Array.from(
                          x.area.match(/(([\-\d\.]+[ ]+[\-\d\.]+))+/g)
                        );
                        const positions = res.reduce((acc, str) => {
                          const spl = str.split(/\s+/);
                          acc.push([parseFloat(spl[1]), parseFloat(spl[0])]);
                          return acc;
                        }, []);
                        return (
                          <Polygon key={idx} positions={positions}>
                            <Tooltip direction="auto" sticky>
                              {x.description}
                            </Tooltip>
                          </Polygon>
                        );
                      })}
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
          </MapContainer>
        </CardContent>
      </Card>
    );
  }
  return null;
}

DatasetsMap.propTypes = {};

export default DatasetsMap;
