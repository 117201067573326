import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Container, FormControl, InputLabel, Select
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Page from 'src/components/Page';
import { mongoDataConstants } from 'src/constants';
import Results from './Results';
import Toolbar from './Toolbar';
import Map from './Map';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function SpectraListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [SelectedIds, setSelectedIds] = useState([]);
  const mems = useSelector((state) => {
    return state.app.get('mems');
  });
  const [open, setOpen] = useState(new Array(500).fill(false));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [server, setServer] = useState(mems.get('server'));

  const changeServer = (event) => {
    dispatch({
      type: mongoDataConstants.MEMS_SERVER,
      payload: event.target.value
    });
    setServer(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: mongoDataConstants.MEMS_CLEAR_ALERT });
    setSnackbarOpen(false);
  };

  if (!snackbarOpen && mems.get('alert').get('message')) {
    setSnackbarOpen(true);
  }

  return (
    <Page className={classes.root} title="MEMS spectra">
      <Container maxWidth={false}>
        <FormControl>
          <InputLabel htmlFor="selected-server">Selected server</InputLabel>
          <Select
            native
            value={server}
            onChange={changeServer}
            label="Selected server"
            inputProps={{
              name: 'server',
              id: 'selected-server'
            }}
          >
            <option value="iBEC">i-BEC</option>
            <option value="ICCS">ICCS</option>
            <option value="iBECd">i-BEC Developing</option>
            <option value="ICCSd">ICCS Developing</option>
          </Select>
        </FormControl>
        {mems.get('alert').get('severity') === 'error' ? (
          <Alert severity="error">{mems.get('alert').get('message')}</Alert>
        ) : (
          <>
            <Toolbar SelectedIds={SelectedIds} setOpen={setOpen} />
            <Box mt={3}>
              <Results
                SelectedIds={SelectedIds}
                setSelectedIds={setSelectedIds}
                open={open}
                setOpen={setOpen}
              />
            </Box>
            <Box mt={3}>
              <Map />
            </Box>
            <Backdrop
              className={classes.backdrop}
              open={mems.get('loading') || mems.get('loadingCustomQuery')}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {mems.get('alert').get('severity') && (
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={mems.get('alert').get('severity')}
                >
                  {mems.get('alert').get('message')}
                </Alert>
              </Snackbar>
            )}
          </>
        )}
      </Container>
    </Page>
  );
}

export default SpectraListView;
