import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';

function Legend({ grades, colors }) {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: 'bottomright' });

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      div.style.padding = '6px 8px';
      div.style.font = '20px/22px Arial, Helvetica, sans-serif';
      div.style.background = 'rgba(255, 255, 255, 0.8)';
      div.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.2)';

      const title = '<p style="font-size: 26px; font-weight: bold;">SOC (g/kg)</p><br>';

      const labels = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];
        const iStyle = 'width: 18px; height: 18px; float: left; margin-right: 8px; margin-top: 2px; line-height: 30px; opacity: 0.7;';
        const thisColor = `background: ${colors[i]}`;

        labels.push(
          `<i style="${iStyle}${thisColor}"></i> ${
            from
          }${to ? `&ndash;${to}` : '+'}`
        );
      }

      div.innerHTML = title + labels.join('<br>');
      return div;
    };

    legend.addTo(map);
  }, []);

  return null;
}

Legend.propTypes = {
  grades: PropTypes.array,
  colors: PropTypes.array
};

export default Legend;
