import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { mongoDataConstants } from 'src/constants';

function DeleteModal({
  open, setOpen, select, setSelect
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const deleteData = () => {
    dispatch({
      type: mongoDataConstants.CHEMISTRY_DELETE_REQUEST,
      payload: select
    });
    setSelect([]);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Are you absolutely sure?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <Button
        color="secondary"
        onClick={deleteData}
      >
        Delete
      </Button>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  select: PropTypes.array,
  setSelect: PropTypes.func
};

export default DeleteModal;
