import {
  call, delay, put, race
} from 'redux-saga/effects';
import { userConstants } from 'src/constants';

const postRequest = async (url, payload) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then((result) => {
      if (!result.ok) {
        return result.json().then((res) => {
          return { message: res.message };
        });
      }
      return result.json().then((res) => {
        return res;
      });
    })
    .catch((error) => {
      console.error('Error: ', error);
      return { message: error };
    });
  return response;
};

const getRequest = async (url) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    }
  })
    .then((result) => {
      if (!result.ok) {
        return result.json().then((res) => {
          return { message: res.message };
        });
      }
      return result.json().then((res) => {
        return res;
      });
    })
    .catch((error) => {
      console.error('Error: ', error);
      return { message: error };
    });
  return response;
};

const updateUser = async (payload) => {
  const bodyPayload = JSON.parse(JSON.stringify(payload));
  delete bodyPayload.id;
  const response = await fetch(`./api/users/${payload.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    },
    body: JSON.stringify(bodyPayload)
  })
    .then((result) => {
      if (result.ok) {
        return result.json().then((res) => {
          return res;
        });
      }
      console.error('Status was not ok, ', result);
      return { message: 'Something went wrong!' };
    })
    .catch((error) => {
      console.error('Error: ', error);
      return { message: error };
    });
  return response;
};

export function* register({ payload }) {
  const raced = yield race({
    posted: call(postRequest, './api/users/register', payload),
    connectionTimeout: delay(10000)
  });

  if (raced.posted) {
    if (!raced.posted.message) {
      yield put({
        type: userConstants.REGISTER_SUCCESS,
        payload: raced.posted
      });
    } else {
      yield put({
        type: userConstants.REGISTER_FAILURE,
        payload: raced.posted.message
      });
    }
  } else {
    yield put({
      type: userConstants.REGISTER_FAILURE,
      payload: 'Connection timeout reached!'
    });
  }
  payload.setSubmitting(false);
}

export function* login({ payload }) {
  const raced = yield race({
    posted: call(postRequest, './api/users/authenticate', payload),
    connectionTimeout: delay(10000)
  });

  if (raced.posted) {
    if (raced.posted.token) {
      // store user details and jwt token in local storage
      // to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(raced.posted));
      yield put({
        type: userConstants.LOGIN_SUCCESS,
        payload: raced.posted
      });
    } else {
      yield put({
        type: userConstants.LOGIN_FAILURE,
        payload: raced.posted.message
      });
    }
  } else {
    yield put({
      type: userConstants.LOGIN_FAILURE,
      payload: 'Connection timeout reached!'
    });
  }

  payload.setSubmitting(false);
}

export function* update({ payload }) {
  if (!payload.id) { // implicitly operate on this user
    payload.id = JSON.parse(localStorage.getItem('user'))._id;
  }
  const actionOnThisUser = payload.id === JSON.parse(localStorage.getItem('user'))._id;

  const raced = yield race({
    updated: call(updateUser, payload),
    connectionTimeout: delay(20000)
  });

  if ('updated' in raced) {
    if (raced.updated.message) {
      yield put({
        type: userConstants.UPDATE_FAILURE,
        payload: raced.updated.message
      });
    } else if (actionOnThisUser) { // Update cookie
      const localUser = JSON.parse(localStorage.getItem('user'));
      Object.keys(payload)
        .filter(
          (key) => typeof payload[key] !== 'function'
            && key !== 'firstName'
            && key !== 'lastName'
            && key !== 'id'
        )
        .map((key) => (localUser[key] = payload[key]));
      if ('firstName' in payload && 'lastName' in payload) {
        localUser.name = `${payload.firstName} ${payload.lastName}`;
      }
      localStorage.setItem('user', JSON.stringify(localUser));
      yield put({
        type: userConstants.UPDATE_SUCCESS,
        payload: localUser
      });
    } else {
      yield put({
        type: userConstants.UPDATE_SUCCESS,
        payload: raced.updated
      });
    }
  } else {
    yield put({
      type: userConstants.UPDATE_FAILURE,
      payload: 'Connection timeout reached!'
    });
  }

  if (payload.setSubmitting) {
    payload.setSubmitting(false);
  }
  if (payload.setOpen) {
    payload.setOpen(false);
  }
}

export function* getAll() {
  const raced = yield race({
    data: call(getRequest, './api/users'),
    connectionTimeout: delay(20000)
  });

  if (raced.data) {
    if (!raced.data.message) {
      yield put({
        type: userConstants.GETALL_SUCCESS,
        payload: raced.data
      });
    } else {
      yield put({
        type: userConstants.GETALL_FAILURE,
        payload: raced.data.message
      });
    }
  } else {
    yield put({
      type: userConstants.GETALL_FAILURE,
      payload: 'Connection timeout reached!'
    });
  }
}

export function* recover({ payload }) {
  const raced = yield race({
    posted: call(postRequest, './api/users/forgot', payload),
    connectionTimeout: delay(10000)
  });

  if (raced.posted) {
    if (raced.posted.success) {
      yield put({
        type: userConstants.RECOVERY_SUCCESS,
        payload: raced.posted.success
      });
    } else {
      yield put({
        type: userConstants.RECOVERY_FAILURE,
        payload: raced.posted.message
      });
      payload.setSubmitting(false);
    }
  } else {
    yield put({
      type: userConstants.RECOVERY_FAILURE,
      payload: 'Connection timeout reached!'
    });
    payload.setSubmitting(false);
  }
}

export function* reset({ payload }) {
  const raced = yield race({
    posted: call(postRequest, '/api/users/reset', payload),
    connectionTimeout: delay(10000)
  });

  if (raced.posted) {
    if (raced.posted.success) {
      yield put({
        type: userConstants.RESET_SUCCESS,
        payload: raced.posted.success
      });
    } else {
      yield put({
        type: userConstants.RESET_FAILURE,
        payload: raced.posted.message
      });
      payload.setSubmitting(false);
    }
  } else {
    yield put({
      type: userConstants.RESET_FAILURE,
      payload: 'Connection timeout reached!'
    });
    payload.setSubmitting(false);
  }
}
