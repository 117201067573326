import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Collapse,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import getInitials from 'src/utils/getInitials';
import { mongoDataConstants } from 'src/constants';
import Spectrum from './Spectrum';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    maxHeight: '70vh'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const genColorFromStr = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '@') break;
    hash = str.charCodeAt(i) + (str.charCodeAt(i) << 5) - (hash >> 2);
  }
  const h = (hash % 60) * 6;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

function Results({
  className,
  SelectedIds,
  setSelectedIds,
  open,
  setOpen,
  ...rest
}) {
  const classes = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.get('user'));
  const mems = useSelector((state) => {
    return state.app.get('mems');
  });

  const handleSetOpen = (event, index) => {
    event.persist();
    if (
      !(
        event.target.attributes
        && event.target.attributes.type
        && event.target.attributes.type.nodeValue === 'checkbox'
      )
    ) {
      setOpen((prevState) => prevState.map((item, idx) => (idx === index ? !item : item)));
    } // else it's a click on the checkbox, don't toggle the table row
  };

  useEffect(() => {
    if (mems.get('loading')) {
      dispatch({
        type: mongoDataConstants.MEMS_DATA_MAKE_DATA_REQUEST,
        payload: {
          page: page + 1,
          pagesize: limit
        }
      });
    }
  }, [dispatch, mems, user, limit, page]);

  const handleSelectAll = (event) => {
    let newSelectedIds;

    if (event.target.checked) {
      newSelectedIds = mems
        .get('data')
        .toJS()
        .map((datum) => datum._id.$oid);
    } else {
      newSelectedIds = [];
    }

    setSelectedIds(newSelectedIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = SelectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(SelectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(SelectedIds.slice(1));
    } else if (selectedIndex === SelectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(SelectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        SelectedIds.slice(0, selectedIndex),
        SelectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectedIds(newSelectedIds);
  };

  const handleLimitChange = (event) => {
    const thisPageStartsFrom = page * limit;
    const futurePage = Math.floor(thisPageStartsFrom / event.target.value);
    setLimit(event.target.value);
    setPage(futurePage);
    dispatch({
      type: mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST,
      payload: true
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setOpen((prevState) => prevState.map((item, idx) => false)); // Collapse all when changing page
    if (!mems.get('customQuerySuccess')) {
      dispatch({
        type: mongoDataConstants.MEMS_DATA_WAIT_DATA_REQUEST,
        payload: true
      });
    }
  };

  const startIndex = mems && mems.get('customQuerySuccess') ? page * limit : 0;

  return mems && mems.get('data') && mems.get('data').size > 0 ? (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={SelectedIds.length === mems.get('data').size}
                    color="primary"
                    indeterminate={
                      SelectedIds.length > 0
                      && SelectedIds.length < mems.get('data').size
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>User</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Sample</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Instrument</TableCell>
                <TableCell>More</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mems
                .get('data')
                .toJS()
                .slice(startIndex, startIndex + limit)
                .map((datum, idx) => (
                  <React.Fragment key={`${datum._id.$oid}-parent`}>
                    <TableRow
                      hover
                      key={datum._id.$oid}
                      selected={SelectedIds.indexOf(datum._id.$oid) !== -1}
                      onClick={(e) => handleSetOpen(e, idx)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={SelectedIds.indexOf(datum._id.$oid) !== -1}
                          onChange={(event) => handleSelectOne(event, datum._id.$oid)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar
                            className={classes.avatar}
                            style={{
                              backgroundColor: genColorFromStr(
                                datum.user.email,
                                30,
                                50
                              )
                            }}
                          >
                            {getInitials(
                              `${datum.user.givenName} ${datum.user.familyName}`
                            )}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {datum.user.familyName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{datum.user.email}</TableCell>
                      <TableCell>
                        {datum.sample && datum.sample.value ? datum.sample.value : 'default'}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={
                            `https://www.openstreetmap.org/?mlat=${
                              datum.location.coords.latitude
                            }&mlon=${
                              datum.location.coords.longitude
                            }&zoom=12&layers=M`
                          }
                          underline="hover"
                        >
                          {`${datum.location.coords.latitude}, ${datum.location.coords.longitude}`}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {moment(datum.date.epoch).format(
                          'HH:mm:ss, DD/MM/YYYY'
                        )}
                      </TableCell>
                      <TableCell>{datum.device.name}</TableCell>
                      <TableCell>
                        {open[idx] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow key={`${datum._id.$oid}-details`}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={8}
                      >
                        <Collapse in={open[idx]} timeout="auto" unmountOnExit>
                          <Spectrum object={datum} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={mems.get('size')}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
      />
    </Card>
  ) : null;
}

Results.propTypes = {
  className: PropTypes.string,
  SelectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  open: PropTypes.array,
  setOpen: PropTypes.func
};

export default Results;
