import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Card, CardContent, Grid, LinearProgress, Typography, colors
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }
}));

function DiskUsage({ available, free, total }) {
  const classes = useStyles();
  const used = (total - available);
  const usedPercentage = (used / total * 100);
  const bytesToTerraBytes = (num) => {
    return (num / 2 ** 40).toFixed(2);
  };

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Disk usage
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {usedPercentage.toFixed(2)}
              {' '}
              % — (
              {bytesToTerraBytes(free)}
              {' '}
              /
              {bytesToTerraBytes(total)}
              {' '}
              TB free)
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <DataUsageOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={usedPercentage}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
}

DiskUsage.propTypes = {
  available: PropTypes.number.isRequired,
  free: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default DiskUsage;
