enum datacubeConstants {
  CLEAR_INIT = 'DATACUBE_CLEAR_INIT',

  ERROR = 'DATACUBE_ERROR',

  MAKE_AREAS_REQUEST = 'DATACUBE_MAKE_AREAS_REQUEST',
  WAIT_AREAS_REQUEST = 'DATACUBE_WAIT_AREAS_REQUEST',
  AREAS_SUCCESS = 'DATACUBE_AREAS_SUCCESS',
  AREAS_FAILURE = 'DATACUBE_AREAS_FAILURE',

  CREATE_AREA_REQUEST = 'DATACUBE_CREATE_AREA_REQUEST',
  EDIT_AREA_REQUEST = 'DATACUBE_EDIT_AREA_REQUEST',
  DELETE_AREA_REQUEST = 'DATACUBE_DELETE_AREA_REQUEST',
  WAIT_AREA_REQUEST = 'DATACUBE_WAIT_AREA_REQUEST',
  CREATE_AREA_SUCCESS = 'DATACUBE_CREATE_AREA_SUCCESS',
  EDIT_AREA_SUCCESS = 'DATACUBE_EDIT_AREA_SUCCESS',
  DELETE_AREA_SUCCESS = 'DATACUBE_DELETE_AREA_SUCCESS',
  CREATE_AREA_FAILURE = 'DATACUBE_CREATE_AREA_FAILURE',
  EDIT_AREA_FAILURE = 'DATACUBE_EDIT_AREA_FAILURE',
  DELETE_AREA_FAILURE = 'DATACUBE_DELETE_AREA_FAILURE',

  SHOW_SNACKBAR = 'DATACUBE_SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'DATACUBE_HIDE_SNACKBAR',

  MAKE_DATASETS_REQUEST = 'DATACUBE_MAKE_DATASETS_REQUEST',
  WAIT_DATASETS_REQUEST = 'DATACUBE_WAIT_DATASETS_REQUEST',
  DELETE_DATASETS_REQUEST = 'DATACUBE_DELETE_DATASETS_REQUEST',
  DELETE_DATASETS_SUCCESS = 'DATACUBE_DELETE_DATASETS_SUCCESS',
  DELETE_DATASETS_FAILURE = 'DATACUBE_DELETE_DATASETS_FAILURE',
  DATASETS_SUCCESS = 'DATACUBE_DATASETS_SUCCESS',
  DATASETS_FAILURE = 'DATACUBE_DATASETS_FAILURE',

  MAKE_QUICKVIEW_REQUEST = 'DATACUBE_MAKE_QUICKVIEW_REQUEST',
  WAIT_QUICKVIEW_REQUEST = 'DATACUBE_WAIT_QUICKVIEW_REQUEST',
  QUICKVIEW_SUCCESS = 'DATACUBE_QUICKVIEW_SUCCESS',
  QUICKVIEW_FAILURE = 'DATACUBE_QUICKVIEW_FAILURE',
  QUICKVIEW_CLEAR = 'DATACUBE_QUICKVIEW_CLEAR',

  MAKE_DISK_USAGE_REQUEST = 'DATACUBE_MAKE_DISK_USAGE_REQUEST',
  WAIT_DISK_USAGE_REQUEST = 'DATACUBE_WAIT_DISK_USAGE_REQUEST',
  DISK_USAGE_SUCCESS = 'DATACUBE_DISK_USAGE_SUCCESS',
  DISK_USAGE_FAILURE = 'DATACUBE_DISK_USAGE_FAILURE',

  MAKE_STATUS_REQUEST = 'DATACUBE_MAKE_STATUS_REQUEST',
  WAIT_STATUS_REQUEST = 'DATACUBE_WAIT_STATUS_REQUEST',
  STATUS_SUCCESS = 'DATACUBE_STATUS_SUCCESS',
  STATUS_FAILURE = 'DATACUBE_STATUS_FAILURE',

  MAKE_SYNC_REQUEST = 'DATACUBE_MAKE_SYNC_REQUEST',
  WAIT_SYNC_REQUEST = 'DATACUBE_WAIT_SYNC_REQUEST',
  SYNC_SUCCESS = 'DATACUBE_SYNC_SUCCESS',
  SYNC_FAILURE = 'DATACUBE_SYNC_FAILURE'
}

export default datacubeConstants;
