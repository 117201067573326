import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { SimpleFileUpload } from 'src/utils/SimpleFileUpload';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { mongoDataConstants, csiroConstants } from 'src/constants';

const useStyles = makeStyles(() => ({
  form: {
    paddingLeft: '24px'
  },
  field: {
    marginBottom: '15px'
  }
}));

const MAX_FILE_SIZE = 2097152; // 2 MB

const schema = Yup.object().shape({
  lbFile: Yup.mixed()
    .required('Required')
    .test(
      'file',
      'File size should be less than 2 MB',
      (value) => value == null || value.size < MAX_FILE_SIZE
    ),
  wbFile: Yup.mixed()
    .required('Required')
    .test(
      'file',
      'File size should be less than 2 MB',
      (value) => value == null || value.size < MAX_FILE_SIZE
    )
});

function StandardizationModal({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const displayWarning = (message) => {
    dispatch({
      type: mongoDataConstants.MEMS_CUSTOM_ALERT,
      payload: {
        severity: 'warning',
        message
      }
    });
    handleClose();
  };

  const checkParsedObject = (obj, which) => {
    const isValid = obj && obj.data && obj.errors && obj.errors.length === 0;
    if (!isValid) {
      displayWarning(
        `An error occurred while parsing the ${
          which
        } csv file. Please check that it is valid.`
      );
    }
    const hasTwoRows = obj.data.length === 2;
    if (!hasTwoRows) {
      displayWarning(`The ${which} csv file must have exactly two rows.`);
    }
    return isValid && hasTwoRows;
  };

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const calculateCorrectionFactors = (lb, wb) => {
    return lb.map(
      (val, index) => (csiroConstants.LB[index] / val
          + csiroConstants.WB[index] / wb[index])
        / 2
    );
  };

  const checkAndProcess = (lb, wb) => {
    if (checkParsedObject(lb, 'LB') && checkParsedObject(wb, 'WB')) {
      if (!arraysEqual(lb.data[0], wb.data[0])) {
        displayWarning('The headers of the two csv files are not the same.');
        return;
      }
      let dataIndexStart = lb.data[0].findIndex((el) => el === '1750');
      let dataIndexEnd = lb.data[0].findIndex((el) => el === '2150');
      if (dataIndexStart === -1) {
        displayWarning('Could not find the wavelength 1750 in the header. Searching for wavelength 2000.');
        dataIndexStart = lb.data[0].findIndex((el) => el === '2000');
        dataIndexEnd = lb.data[0].findIndex((el) => el === '2450');
        if (dataIndexStart === -1) {
          displayWarning('Could not find the wavelength 2000 in the header.');
          return;
        }
      }
      const instrumentIndex = lb.data[0].findIndex((el) => el === 'instrument');
      if (instrumentIndex === -1) {
        displayWarning('A column named instrument should exist in the header.');
        return;
      } if (lb.data[1][instrumentIndex] !== wb.data[1][instrumentIndex]) {
        displayWarning('The instrument names in the two csv files do not match.');
        return;
      }
      const lbSpectrum = lb.data[1].slice(dataIndexStart, dataIndexEnd + 1).map((el) => Number(el));
      const wbSpectrum = wb.data[1].slice(dataIndexStart, dataIndexEnd + 1).map((el) => Number(el));
      const cf = calculateCorrectionFactors(lbSpectrum, wbSpectrum);
      dispatch({
        type: mongoDataConstants.MEMS_PUSH_CF_REQUEST,
        payload: {
          device: lb.data[1][instrumentIndex],
          correctionFactors: cf,
          LB: lbSpectrum,
          WB: wbSpectrum
        },
        setOpen
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={{
          lbFile: '',
          wbFile: ''
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          const lbReader = new FileReader();
          lbReader.readAsText(values.lbFile);
          lbReader.onload = () => {
            const wbReader = new FileReader();
            wbReader.readAsText(values.wbFile);
            wbReader.onload = () => {
              const lb = Papa.parse(lbReader.result, { skipEmptyLines: true });
              const wb = Papa.parse(wbReader.result, { skipEmptyLines: true });
              checkAndProcess(lb, wb);
            };
          };
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <DialogTitle id="form-dialog-title">
              Add standardization reference files
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This form allows you to upload the reference measurements of the
                WB and LB soil samples for a specific device. Then, future
                measurements from this device will be auto-standardized to the
                CSIRO standard. Please be careful!
              </DialogContentText>
            </DialogContent>
            <Form className={classes.form}>
              <div className={classes.field}>
                <Field
                  component={SimpleFileUpload}
                  name="lbFile"
                  label="Lucky Bay"
                  accept=".csv"
                />
              </div>
              <div className={classes.field}>
                <Field
                  component={SimpleFileUpload}
                  name="wbFile"
                  label="Wylie Bay"
                  accept=".csv"
                />
              </div>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

StandardizationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default StandardizationModal;
