export function extractTileFromStr(str) {
  const tile_regex = /_([A-Z0-9]{6})_[0-9T]*\.SAFE/g;
  let results = str.matchAll(tile_regex);
  results = Array.from(results);
  return results[0][1];
}

export function extractProcessVersion(location) {
  // Define the regular expression pattern to match the desired substring
  const regex = /_N(\d+)_/;

  // Extract the matching substring using the regular expression
  const match = location.match(regex);

  // If a match is found, return the extracted substring, else return null or handle accordingly
  return match ? match[1] : null;
}
