import React, { useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { userConstants } from 'src/constants';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout() {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.app.get('user');
  });
  const location = useLocation();
  const logOut = () => {
    localStorage.removeItem('user');
    dispatch({ type: userConstants.LOGOUT });
  };

  const shouldBeHidden = () => {
    return ['/app/datacube', '/app/customers', '/app/chemistry'].includes(location.pathname);
  };

  if (!user) {
    // check if it's stored in the cookie
    const luser = JSON.parse(localStorage.getItem('user'));
    if (luser && luser.token) {
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: luser
      });
      if (luser.role !== 'Administrator' && shouldBeHidden()) {
        return <Navigate to="/404" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else if (user.get('role') !== 'Administrator' && shouldBeHidden()) {
    return <Navigate to="/404" />;
  }

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} logOut={logOut} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        logOut={logOut}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
