import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { userConstants } from 'src/constants';

const useStyles = makeStyles(() => ({
  formElement: {
    display: 'flex'
  }
}));

function ProfileDetails({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.app.get('user');
  });

  return (
    <Formik
      initialValues={{
        firstName: user.get('name').slice(0, user.get('name').lastIndexOf(' ')),
        lastName: user.get('name').slice(user.get('name').lastIndexOf(' ') + 1),
        email: user.get('email')
      }}
      validationSchema={
        Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          firstName: Yup.string().max(255).required('First name is required'),
          lastName: Yup.string().max(255).required('Last name is required'),
        })
      }
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: userConstants.UPDATE_REQUEST,
          payload: { ...values, setSubmitting }
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Card>
            <CardHeader
              subheader="Μπορείτε να πραγματοποιήσετε αλλαγές στις πληροφορίες"
              title="Προφίλ χρήστη"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    className={classes.formElement}
                    name="firstName"
                    type="text"
                    label="Όνομα"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    className={classes.formElement}
                    name="lastName"
                    type="text"
                    label="Επίθετο"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    className={classes.formElement}
                    name="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    disabled={user.get('role') !== 'Administrator'}
                  />
                </Grid>
                {isSubmitting && <CircularProgress />}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                ΑΠΟΘΗΚΕΥΣΗ
              </Button>
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
