import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { datacubeConstants } from 'src/constants';
import AreaForm from './AreaForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    minHeight: '488px'
  },
  tableContainer: {
    maxHeight: '450px'
  },
  descriptionCell: {
    minWidth: '250px'
  },
  actionCell: {
    minWidth: '220px'
  },
  table: {
    height: '100%'
  },
  button: {
    margin: theme.spacing(0.5)
  }
}));

function AreasTable({ setSnackbarOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editAreaOpen, setEditAreaOpen] = React.useState(false);
  const [selectedArea, setSelectedArea] = React.useState({});
  const [newAreaOpen, setNewAreaOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const datacube = useSelector((state) => state.datacube);

  const editArea = (id) => {
    setSelectedArea(
      datacube
        .get('areas')
        .toJS()
        .filter((x) => x.id === id)[0]
    );
    setEditAreaOpen(true);
  };

  const createNewArea = () => {
    setNewAreaOpen(true);
  };

  const deleteArea = () => {
    dispatch({
      type: datacubeConstants.DELETE_AREA_REQUEST,
      payload: selectedArea.id,
      showSnackbar: setSnackbarOpen
    });
  };

  if (datacube && datacube.get('areas') && datacube.get('areas').size > 0) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Table of areas automatically monitored" />
        <Divider />
        <CardContent className={classes.container}>
          <PerfectScrollbar>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                aria-label="areas table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.descriptionCell}>
                      Description
                    </TableCell>
                    <TableCell>WKT</TableCell>
                    <TableCell>Start date</TableCell>
                    <TableCell>End date</TableCell>
                    <TableCell className={classes.actionCell}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datacube
                    .get('areas')
                    .toJS()
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell>{row.area}</TableCell>
                        <TableCell>
                          {row.start_date
                            ? moment(row.start_date, 'YYYYMMDD').format(
                              'DD/MM/YYYY'
                            )
                            : ''}
                        </TableCell>
                        <TableCell>
                          {row.end_date
                            ? moment(row.end_date, 'YYYYMMDD').format(
                              'DD/MM/YYYY'
                            )
                            : ''}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<EditIcon />}
                            className={classes.button}
                            onClick={() => editArea(row.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              setSelectedArea(row);
                              setConfirmDeleteOpen(true);
                            }}
                            className={classes.button}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <ConfirmDialog
                  title="Delete area?"
                  open={confirmDeleteOpen}
                  setOpen={setConfirmDeleteOpen}
                  onConfirm={() => {
                    deleteArea();
                  }}
                >
                  <Typography variant="body1">
                    Are you sure you want to delete area "
                    {selectedArea.description}
                    ",
                    {selectedArea.area}
                    ?
                  </Typography>
                </ConfirmDialog>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={createNewArea}>
            Add new entry
          </Button>
        </CardActions>
        <AreaForm
          title="Create new entry"
          open={newAreaOpen}
          setOpen={setNewAreaOpen}
          showSnackbar={setSnackbarOpen}
        />
        <AreaForm
          title="Edit entry"
          open={editAreaOpen}
          setOpen={setEditAreaOpen}
          showSnackbar={setSnackbarOpen}
          area={selectedArea}
        />
      </Card>
    );
  }
  return null;
}

AreasTable.propTypes = {
  setSnackbarOpen: PropTypes.func.isRequired
};

export default AreasTable;
