// @ts-nocheck
import { fromJS } from 'immutable';
import { Area, DatacubeState, DatacubeActionTypes } from './types';
import { datacubeConstants } from 'src/constants';

const initialState: DatacubeState = {
  areas: [],
  datasets: [],
  quickview: '',
  disk: {
    available: 0,
    free: 0,
    total: 0
  },
  status: {
    active: false,
    timer: {}
  },
  loading: true,
  error: '',
  snackbar: {
    message: '',
    severity: 'info'
  },
  init: true
};

export function datacubeReducer(
  state = fromJS(initialState),
  action: DatacubeActionTypes
): DatacubeState {
  switch (action.type) {
    case datacubeConstants.CLEAR_INIT:
      return state.set('init', false);
    case datacubeConstants.QUICKVIEW_CLEAR:
      return state.set('quickview', '');
    case datacubeConstants.WAIT_DATASETS_REQUEST:
    case datacubeConstants.WAIT_QUICKVIEW_REQUEST:
    case datacubeConstants.WAIT_SYNC_REQUEST:
      return state.set('loading', action.payload);
    case datacubeConstants.DATASETS_SUCCESS:
      return state.set('datasets', fromJS(action.payload));
    case datacubeConstants.QUICKVIEW_SUCCESS:
      return state.set('quickview', fromJS(action.payload));
    case datacubeConstants.AREAS_SUCCESS:
      return state.set('areas', fromJS(action.payload));
    case datacubeConstants.DISK_USAGE_SUCCESS:
      return state.set('disk', fromJS(action.payload));
    case datacubeConstants.STATUS_SUCCESS:
      return state.set('status', fromJS(action.payload));
    case datacubeConstants.CREATE_AREA_SUCCESS:
      return state
        .updateIn(['areas'], (arr: Array<Area>) =>
          arr.push(fromJS(action.area))
        )
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'success');
    case datacubeConstants.EDIT_AREA_SUCCESS:
      const areaIndex = state
        .get('areas')
        .findIndex((i: any) => i.get('id') === action.area.id);
      return state
        .updateIn(['areas', areaIndex], (obj: any) => fromJS(action.area))
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'success');
    case datacubeConstants.DELETE_AREA_SUCCESS:
      return state
        .updateIn(['areas'], (arr: Array<any>) =>
          arr.filter((x: any) => x.get('id') !== action.data)
        )
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'success');
    case datacubeConstants.DELETE_DATASETS_SUCCESS:
      return state
        .updateIn(['datasets'], (arr: Array<any>) =>
          arr.filter((x: any) => x.get('id') !== action.data)
        )
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'success');
    case datacubeConstants.SYNC_SUCCESS:
      return state
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'info')
    case datacubeConstants.CREATE_AREA_FAILURE:
    case datacubeConstants.EDIT_AREA_FAILURE:
    case datacubeConstants.DELETE_AREA_FAILURE:
    case datacubeConstants.SYNC_FAILURE:
      return state
        .setIn(['snackbar', 'message'], fromJS(action.message))
        .setIn(['snackbar', 'severity'], 'error');
    case datacubeConstants.ERROR:
    case datacubeConstants.DATASETS_FAILURE:
    case datacubeConstants.QUICKVIEW_FAILURE:
    case datacubeConstants.AREAS_FAILURE:
    case datacubeConstants.DISK_USAGE_FAILURE:
    case datacubeConstants.STATUS_FAILURE:
      return state.set('error', fromJS(action.message));
    default:
      return state;
  }
}
