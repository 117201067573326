import { takeLatest } from 'redux-saga/effects';
import {
  alertConstants,
  userConstants,
  mongoDataConstants,
  datacubeConstants,
  geodataConstants
} from 'src/constants';
import handleError from 'src/sagas/handleError';
import {
  register,
  login,
  recover,
  reset,
  update,
  getAll
} from 'src/sagas/userActions';
import {
  getMEMSdata,
  getMEMScustom,
  getMEMSphoto,
  postMEMScfs,
  getFDtoolPoints,
  getFDtoolPhoto,
  getInsitu,
  getChemistryData,
  postChemistry,
  postChemistryDelete,
  getChemistryCustom,
} from 'src/sagas/restheartActions';
import {
  getDatacubeAreas,
  createDatacubeArea,
  editDatacubeArea,
  deleteDatacubeArea,
  deleteDatacubeDataset,
  getDatacubeDatasets,
  getDatacubeQuickview,
  getDiskUsage,
  getStatus,
  forceSync
} from 'src/sagas/datacubeActions';
import { getPointValue } from 'src/sagas/geodataActions';

// suppress the warnings: https://stackoverflow.com/a/74361773
function* mySaga() {
  yield takeLatest(alertConstants.ERROR, handleError);

  yield takeLatest(userConstants.REGISTER_REQUEST, register);
  yield takeLatest(userConstants.LOGIN_REQUEST, login);
  yield takeLatest(userConstants.RECOVERY_REQUEST, recover);
  yield takeLatest(userConstants.RESET_REQUEST, reset);
  yield takeLatest(userConstants.UPDATE_REQUEST, update);
  yield takeLatest(userConstants.GETALL_REQUEST, getAll);

  yield takeLatest(mongoDataConstants.MEMS_DATA_MAKE_DATA_REQUEST, getMEMSdata);
  yield takeLatest(mongoDataConstants.CHEMISTRY_DATA_MAKE_DATA_REQUEST, getChemistryData);
  yield takeLatest(
    mongoDataConstants.MEMS_DATA_MAKE_CUSTOM_REQUEST,
    getMEMScustom
  );
  yield takeLatest(
    mongoDataConstants.CHEMISTRY_DATA_MAKE_CUSTOM_REQUEST,
    getChemistryCustom
  );
  yield takeLatest(
    mongoDataConstants.MEMS_DATA_MAKE_PHOTO_REQUEST,
    getMEMSphoto
  );

  yield takeLatest(mongoDataConstants.MEMS_PUSH_CF_REQUEST, postMEMScfs);
  yield takeLatest(mongoDataConstants.CHEMISTRY_PUSH_REQUEST, postChemistry);
  yield takeLatest(mongoDataConstants.CHEMISTRY_DELETE_REQUEST, postChemistryDelete);

  yield takeLatest(
    mongoDataConstants.FDTOOL_MAKE_DATA_REQUEST,
    getFDtoolPoints
  );
  yield takeLatest(
    mongoDataConstants.FDTOOL_MAKE_PHOTO_REQUEST,
    getFDtoolPhoto
  );

  yield takeLatest(mongoDataConstants.INSITU_MAKE_DATA_REQUEST, getInsitu);

  yield takeLatest(
    datacubeConstants.MAKE_DATASETS_REQUEST,
    getDatacubeDatasets
  );
  yield takeLatest(
    datacubeConstants.MAKE_QUICKVIEW_REQUEST,
    getDatacubeQuickview
  );
  yield takeLatest(datacubeConstants.MAKE_AREAS_REQUEST, getDatacubeAreas);
  yield takeLatest(datacubeConstants.DELETE_DATASETS_REQUEST, deleteDatacubeDataset);
  yield takeLatest(datacubeConstants.MAKE_DISK_USAGE_REQUEST, getDiskUsage);
  yield takeLatest(datacubeConstants.MAKE_STATUS_REQUEST, getStatus);
  yield takeLatest(datacubeConstants.CREATE_AREA_REQUEST, createDatacubeArea);
  yield takeLatest(datacubeConstants.EDIT_AREA_REQUEST, editDatacubeArea);
  yield takeLatest(datacubeConstants.DELETE_AREA_REQUEST, deleteDatacubeArea);
  yield takeLatest(datacubeConstants.MAKE_SYNC_REQUEST, forceSync);

  yield takeLatest(geodataConstants.POINT_REQUEST, getPointValue);
}

export default mySaga;
