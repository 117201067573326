import { Area, Dataset, Disk, DatacubeActionTypes, Status } from './types';
import { datacubeConstants } from 'src/constants';

export function setAreas(areas: Area[]): DatacubeActionTypes {
  return {
    type: datacubeConstants.AREAS_SUCCESS,
    payload: areas
  };
}

export function setDisk(disk: Disk): DatacubeActionTypes {
  return {
    type: datacubeConstants.DISK_USAGE_SUCCESS,
    payload: disk
  };
}

export function setDatasets(datasets: Dataset[]): DatacubeActionTypes {
  return {
    type: datacubeConstants.DATASETS_SUCCESS,
    payload: datasets
  };
}

export function setStatus(status: Status): DatacubeActionTypes {
  return {
    type: datacubeConstants.STATUS_SUCCESS,
    payload: status
  };
}

export function setQuickview(image: string) : DatacubeActionTypes {
  return {
    type: datacubeConstants.QUICKVIEW_SUCCESS,
    payload: image
  }
}