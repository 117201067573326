const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  RECOVERY_REQUEST: 'USERS_RECOVERY_REQUEST',
  RECOVERY_SUCCESS: 'USERS_RECOVERY_SUCCESS',
  RECOVERY_FAILURE: 'USERS_RECOVERY_FAILURE',

  RESET_REQUEST: 'USERS_RESET_REQUEST',
  RESET_SUCCESS: 'USERS_RESET_SUCCESS',
  RESET_FAILURE: 'USERS_RESET_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',
};

export default userConstants;
