import React from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
// this should be updated once formik-material-ui releases a new version
import { SimpleFileUpload } from 'src/utils/SimpleFileUpload';
import * as Yup from 'yup';
import { userConstants } from 'src/constants';

const useStyles = makeStyles(() => ({
  fileUpload: {
    paddingLeft: '24px'
  }
}));

const MAX_FILE_SIZE = 2097152; // 2 MB

const schema = Yup.object().shape({
  file: Yup.mixed()
    .required('Required')
    .test(
      'file',
      'Το αρχείο θα πρέπει να είναι μικρότερο από 2 MB',
      (value) => value == null || value.size < MAX_FILE_SIZE
    )
});

export default function AvatarDialog({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={{
          file: ''
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          const reader = new FileReader();
          reader.readAsDataURL(values.file);
          reader.onload = () => {
            const ext = values.file.type.substr(
              values.file.type.indexOf('/') + 1
            );
            const img = reader.result.substr(reader.result.indexOf(',') + 1);
            dispatch({
              type: userConstants.UPDATE_REQUEST,
              payload: {
                avatar_base64: img,
                avatar_ext: ext,
                setOpen,
                setSubmitting
              }
            });
          };
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <DialogTitle id="form-dialog-title">Avatar</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Παρακαλώ επιλέξτε ένα αρχείο εικόνας από τον υπολογιστή σας
              </DialogContentText>
              {isSubmitting && <CircularProgress />}
            </DialogContent>
            <Form className={classes.fileUpload}>
              <Field
                component={SimpleFileUpload}
                name="file"
                label="Εικόνα"
                accept="image/png, image/jpeg"
              />
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  ΑΚΥΡΟ
                </Button>
                <Button onClick={submitForm} color="primary">
                  ΥΠΟΒΟΛΗ
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
