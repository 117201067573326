import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

function LineChart({
  spectra, MinWL, MaxWL, Step, isReflectance
}) {
  const isObject = (a) => {
    return (!!a) && (a.constructor === Object);
  };

  const getDatasets = () => {
    if (isObject(spectra)) {
      const datasets = [];
      const colors = ['255, 99, 132', '55,126,184', '77,175,74', '255,255,51'];
      for (const key in spectra) {
        const idx = Object.keys(spectra).indexOf(key);
        datasets.push({
          data: spectra[key],
          label: key,
          fill: false,
          backgroundColor: `rgb(${colors[idx]})`,
          borderColor: `rgba(${colors[idx]})`,
        });
      }
      return datasets;
    }
    return [
      {
        data: spectra,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.8)'
      }
    ];
  };

  const range = (start, end, step = 1) => {
    const allNumbers = [start, end, step].every(Number.isFinite);

    if (!allNumbers) {
      throw new TypeError('range() expects only finite numbers as arguments.');
    }

    if (step <= 0) {
      throw new Error('step must be a number greater than 0.');
    }

    if (start > end) {
      step = -step;
    }

    const length = Math.floor(Math.abs((end - start) / step)) + 1;
    return Array.from(Array(length), (x, index) => start + index * step);
  };

  const data = {
    labels: range(MinWL, MaxWL, Step),
    datasets: getDatasets()
  };

  const options = {
    legend: {
      display: isObject(spectra)
    },
    elements: {
      point: {
        radius: 0
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += Math.round(tooltipItem.yLabel * 10000) / 10000;
          return label;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {
        scaleLabel: {
          display: true,
          labelString: 'Wavelength (nm)'
        }
      }
    }
  };

  if (isReflectance) {
    options.scales.yAxes = new Array({
      ticks: {
        min: 0,
        max: 1
      }
    });
  }

  return <Line data={data} options={options} />;
}

LineChart.propTypes = {
  spectra: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  MinWL: PropTypes.number.isRequired,
  MaxWL: PropTypes.number.isRequired,
  Step: PropTypes.number.isRequired,
  isReflectance: PropTypes.bool
};

LineChart.defaultProps = {
  isReflectance: false
};

export default LineChart;
