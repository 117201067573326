import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function CustomerListView() {
  const classes = useStyles();
  const users = useSelector((state) => {
    return state.app.get('users');
  });

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results />
        </Box>
        <Backdrop className={classes.backdrop} open={users.get('loading')}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Page>
  );
}

export default CustomerListView;
