import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Page from 'src/components/Page';
import Snackbar from '@mui/material/Snackbar';
import { datacubeConstants } from 'src/constants';
import DatasetsChart from './DatasetsChart';
import DatasetsMap from './DatasetsMap';
import DatasetsTable from './DatasetsTable';
import AreasTable from './AreasTable';
import DiskUsage from './DiskUsage';
import ServiceStatus from './ServiceStatus';
import LogViewer from './LogViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Helpers({ snackbarOpen, handleSnackbarClose }) {
  const classes = useStyles();
  const init = useSelector((state) => state.datacube.get('init'));
  const snackbar = useSelector((state) => state.datacube.get('snackbar'));
  const loading = useSelector((state) => state.datacube.get('loading'));

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading || init}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.get('severity')}
        >
          {snackbar.get('message')}
        </Alert>
      </Snackbar>
    </>
  );
}

function DatacubeAdminView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const init = useSelector((state) => state.datacube.get('init'));
  const error = useSelector((state) => state.datacube.get('error'));
  const status = useSelector((state) => state.datacube.get('status'));
  const disk = useSelector((state) => state.datacube.get('disk'));
  const user = useSelector((state) => state.app.get('user'));

  useEffect(() => {
    if (init) {
      dispatch({ type: datacubeConstants.CLEAR_INIT });
      dispatch({
        type: datacubeConstants.MAKE_DATASETS_REQUEST
      });
      dispatch({
        type: datacubeConstants.MAKE_AREAS_REQUEST
      });
      dispatch({
        type: datacubeConstants.MAKE_DISK_USAGE_REQUEST
      });
      dispatch({
        type: datacubeConstants.MAKE_STATUS_REQUEST
      });
    }
  }, [dispatch, init, user]);

  const makeSync = () => {
    dispatch({
      type: datacubeConstants.MAKE_SYNC_REQUEST,
      showSnackbar: setSnackbarOpen
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <Page className={classes.root} title="Datacube administrator panel">
      <Container maxWidth={false}>
        {error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <Grid container mt={2} spacing={3}>
              {status.get('timer') && (
                <Grid item xs={12} md={6}>
                  <ServiceStatus
                    active={status.get('active')}
                    lastSync={
                      status.get('timer').get('LastTriggerUSec') || 'unknown'
                    }
                    nextSync={
                      status.get('timer').get('NextElapseUSecRealtime')
                      || 'unknown'
                    }
                    makeSync={makeSync}
                    setSnackbarOpen={setSnackbarOpen}
                  />
                </Grid>
              )}
              {disk.get('free') && (
                <Grid item xs={12} md={6}>
                  <DiskUsage
                    free={disk.get('free')}
                    available={disk.get('available')}
                    total={disk.get('total')}
                  />
                </Grid>
              )}
              <Grid item xs={12} xl={6}>
                <AreasTable setSnackbarOpen={setSnackbarOpen} />
              </Grid>
              <Grid item xs={12} xl={6}>
                <DatasetsMap />
              </Grid>
              <Grid item xs={12} xl={6}>
                <DatasetsTable setSnackbarOpen={setSnackbarOpen} />
              </Grid>
              <Grid item xs={12} xl={6}>
                <DatasetsChart />
              </Grid>
              <Grid item xs={12}>
                <LogViewer />
              </Grid>
            </Grid>
            <Helpers
              snackbarOpen={snackbarOpen}
              handleSnackbarClose={handleSnackbarClose}
            />
          </>
        )}
      </Container>
    </Page>
  );
}

export default DatacubeAdminView;
