import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { datacubeConstants } from 'src/constants';
import { red } from '@mui/material/colors';
import { extractTileFromStr, extractProcessVersion } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  },
  container: {
    height: '500px',
    width: '100%'
  }
}));

function Quickview() {
  const quickview = useSelector((state) => state.datacube.get('quickview'));
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: datacubeConstants.QUICKVIEW_CLEAR });
  };

  return quickview.length > 0 ? (
    <Dialog onClose={handleClose} aria-labelledby="image-dialog" open>
      <DialogTitle id="image-dialog-title">Quickview image</DialogTitle>
      <img alt="satellite" src={`data:image/png;base64,${quickview}`} />
    </Dialog>
  ) : null;
}

function DatasetsTable({ setSnackbarOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const datasets = useSelector((state) => state.datacube.get('datasets'));
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [selectedDataset, setSelectedDataset] = React.useState({});

  const deleteDataset = () => {
    dispatch({
      type: datacubeConstants.DELETE_DATASETS_REQUEST,
      id: selectedDataset.id,
      showSnackbar: setSnackbarOpen
    });
    console.log(`You tried to delete ${selectedDataset.id}`);
  };

  if (datasets && datasets.size > 0) {
    const columns = [
      {
        field: 'id', headerName: 'ID', width: 300, hide: true
      },
      { field: 'product', headerName: 'Product', width: 200 },
      {
        field: 'img_creation_date',
        headerName: 'Image Creation Date',
        width: 150,
        valueFormatter: (value) => moment(value.valueOf()).format('DD/MM/YYYY')
      },
      {
        field: 'img_taken_date',
        headerName: 'Image Taken Date',
        width: 150,
        valueFormatter: (value) => moment(value.valueOf()).format('DD/MM/YYYY')
      },
      { field: 'process_version', headerName: 'Process Version', width: 100},
      { field: 'status', headerName: 'Status', width: 100 },
      { field: 'tile', headerName: 'Tile', width: 150 },
      {
        field: 'image',
        headerName: 'Image',
        width: 100,
        renderCell: (params) => {
          const onClick = () => {
            dispatch({
              type: datacubeConstants.MAKE_QUICKVIEW_REQUEST,
              id: params.row.id
            });
          };
          return (
            <Button onClick={onClick} color="primary">
              Show
            </Button>
          );
        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: red[500] }} />}
            label="Delete"
            onClick={() => {
              setSelectedDataset(params.row);
              setConfirmDeleteOpen(true);
              console.log(`Hello from ${params.row.id}`);
            }}
          />,],
      }
    ];
    const rows = datasets.toJS().map((x) => {
      return {
        id: x.id,
        product: x.product,
        img_creation_date: new Date(x.fields.creation_time),
        img_taken_date: new Date(x.fields.time.begin),
        process_version: extractProcessVersion(x.locations[0]),
        status: x.status,
        tile: extractTileFromStr(x.locations[0])
      };
    });
    return (
      <Card className={classes.root}>
        <CardHeader title="Table of indexed datasets" />
        <Divider />
        <CardContent className={classes.container}>
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              toolbar: GridToolbar
            }}
            initialState={{
              pagination: {
                pageSize: 100
              },
              sorting: {
                sortModel: [
                  {
                    field: 'date',
                    sort: 'desc'
                  }
                ]
              }
            }}
          />
        </CardContent>
        <ConfirmDialog
          title="Delete tile?"
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={() => {
            deleteDataset();
          }}
        >
          <Typography variant="body1">
            Are you sure you want to delete tile '
            {selectedDataset.product}
            '
            {selectedDataset.tile}
            ?
          </Typography>
        </ConfirmDialog>
        <Quickview />
      </Card>
    );
  }
  return null;
}

DatasetsTable.propTypes = {
  setSnackbarOpen: PropTypes.func.isRequired
};

export default DatasetsTable;
