const mongoDataConstants = {
  MEMS_DATA_MAKE_DATA_REQUEST: 'MEMS_DATA_MAKE_DATA_REQUEST',
  MEMS_DATA_WAIT_DATA_REQUEST: 'MEMS_DATA_WAIT_DATA_REQUEST',
  MEMS_DATA_SUCCESS: 'MEMS_DATA_SUCCESS',
  MEMS_DATA_FAILURE: 'MEMS_DATA_FAILURE',

  MEMS_DATA_MAKE_CUSTOM_REQUEST: 'MEMS_DATA_MAKE_CUSTOM_REQUEST',
  MEMS_DATA_WAIT_CUSTOM_REQUEST: 'MEMS_DATA_WAIT_CUSTOM_REQUEST',
  MEMS_DATA_CUSTOM_SUCCESS: 'MEMS_DATA_CUSTOM_SUCCESS',
  MEMS_DATA_CUSTOM_FAILURE: 'MEMS_DATA_CUSTOM_FAILURE',

  MEMS_DATA_MAKE_PHOTO_REQUEST: 'MEMS_DATA_MAKE_PHOTO_REQUEST',
  MEMS_DATA_WAIT_PHOTO_REQUEST: 'MEMS_DATA_WAIT_PHOTO_REQUEST',
  MEMS_DATA_PHOTO_SUCCESS: 'MEMS_DATA_PHOTO_SUCCESS',
  MEMS_DATA_PHOTO_FAILURE: 'MEMS_DATA_PHOTO_FAILURE',

  MEMS_PUSH_CF_REQUEST: 'MEMS_PUSH_CF_REQUEST',
  MEMS_WAIT_CF_REQUEST: 'MEMS_WAIT_CF_REQUEST',
  MEMS_PUSH_CF_SUCCESS: 'MEMS_PUSH_CF_SUCCESS',
  MEMS_PUSH_CF_FAILURE: 'MEMS_PUSH_CF_FAILURE',

  MEMS_CUSTOM_ALERT: 'MEMS_CUSTOM_ALERT',
  MEMS_CLEAR_ALERT: 'MEMS_CLEAR_ALERT',

  MEMS_SERVER: 'MEMS_SERVER',
  

  CHEMISTRY_DELETE_REQUEST: 'CHEMISTRY_DELETE_REQUEST',
  CHEMISTRY_DELETE_WAIT_REQUEST: 'CHEMISTRY_DELETE_WAIT_REQUEST',
  CHEMISTRY_DELETE_SUCCESS: 'CHEMISTRY_DELETE_SUCCESS',
  CHEMISTRY_DELETE_FAILURE: 'CHEMISTRY_DELETE_FAILURE',

  CHEMISTRY_DATA_MAKE_DATA_REQUEST: 'CHEMISTRY_DATA_MAKE_DATA_REQUEST',
  CHEMISTRY_DATA_FAILURE: 'CHEMISTRY_DATA_FAILURE',
  CHEMISTRY_DATA_WAIT_DATA_REQUEST: 'CHEMISTRY_DATA_WAIT_DATA_REQUEST',
  CHEMISTRY_DATA_SUCCESS: 'CHEMISTRY_DATA_SUCCESS',
  CHEMISTRY_CLEAR_ALERT: 'CHEMISTRY_CLEAR_ALERT',

  CHEMISTRY_DATA_MAKE_CUSTOM_REQUEST: 'CHEMISTRY_DATA_MAKE_CUSTOM_REQUEST',
  CHEMISTRY_DATA_WAIT_CUSTOM_REQUEST: 'CHEMISTRY_DATA_WAIT_CUSTOM_REQUEST',
  CHEMISTRY_DATA_CUSTOM_SUCCESS: 'CHEMISTRY_DATA_CUSTOM_SUCCESS',
  CHEMISTRY_CUSTOM_ALERT: 'CHEMISTRY_CUSTOM_ALERT',

  CHEMISTRY_DATA_WAIT_PHOTO_REQUEST: 'CHEMISTRY_DATA_WAIT_PHOTO_REQUEST',
  CHEMISTRY_DATA_PHOTO_SUCCESS: 'CHEMISTRY_DATA_PHOTO_SUCCESS',

  CHEMISTRY_PUSH_REQUEST: 'CHEMISTRY_PUSH_REQUEST',
  CHEMISTRY_PUSH_SUCCESS: 'CHEMISTRY_PUSH_SUCCESS',
  CHEMISTRY_PUSH_FAILURE: 'CHEMISTRY_PUSH_FAILURE',
  CHEMISTRY_WAIT_REQUEST: 'CHEMISTRY_WAIT_REQUEST',

  FDTOOL_MAKE_DATA_REQUEST: 'FDTOOL_MAKE_DATA_REQUEST',
  FDTOOL_WAIT_DATA_REQUEST: 'FDTOOL_WAIT_DATA_REQUEST',
  FDTOOL_DATA_SUCCESS: 'FDTOOL_DATA_SUCCESS',
  FDTOOL_DATA_FAILURE: 'FDTOOL_DATA_FAILURE',

  FDTOOL_MAKE_PHOTO_REQUEST: 'FDTOOL_MAKE_PHOTO_REQUEST',
  FDTOOL_WAIT_PHOTO_REQUEST: 'FDTOOL_WAIT_PHOTO_REQUEST',
  FDTOOL_PHOTO_SUCCESS: 'FDTOOL_PHOTO_SUCCESS',
  FDTOOL_PHOTO_FAILURE: 'FDTOOL_PHOTO_FAILURE',

  INSITU_MAKE_DATA_REQUEST: 'INSITU_MAKE_DATA_REQUEST',
  INSITU_WAIT_DATA_REQUEST: 'INSITU_WAIT_DATA_REQUEST',
  INSITU_DATA_SUCCESS: 'INSITU_DATA_SUCCESS',
  INSITU_DATA_FAILURE: 'INSITU_DATA_FAILURE',
};

export default mongoDataConstants;
