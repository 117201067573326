import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import { LazyLog } from 'react-lazylog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    minHeight: '500px'
  }
}));

function LogViewer() {
  const classes = useStyles();
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    }
  };
  return (
    <Card className={classes.root}>
      <CardHeader title="Log file viewer" />
      <Divider />
      <CardContent className={classes.container}>
        <LazyLog
          url="./api/datacube/log"
          fetchOptions={fetchOptions}
          enableSearch
        />
      </CardContent>
    </Card>
  );
}

export default LogViewer;
