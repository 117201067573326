import React from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import {
  LayersControl,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  WMSTileLayer,
  useMap,
  useMapEvents
} from 'react-leaflet';
import { geodataConstants, leafletConstants } from 'src/constants';
import Legend from './Legend';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    minHeight: '70v'
  },
  map: {
    height: '70vh'
  }
}));

function LocationMarker({ layer }) {
  const [position, setPosition] = React.useState(null);
  const [pointValue, setPointValue] = React.useState(null);

  const map = useMap();
  const dispatch = useDispatch();

  useMapEvents({
    click(e) {
      const BBOX = map.getBounds().toBBoxString();
      const WIDTH = map.getSize().x;
      const HEIGHT = map.getSize().y;
      const X = map.layerPointToContainerPoint(e.layerPoint).x;
      const Y = map.layerPointToContainerPoint(e.layerPoint).y;
      const URL = `worldsoils/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image/png&TRANSPARENT=true&QUERY_LAYERS=${
        layer}&LAYERS=${layer}&exceptions=application/vnd.ogc.se_inimage&INFO_FORMAT=application/json&FEATURE_COUNT=1`
                + `&X=${X.toFixed(0)}&Y=${Y.toFixed(0)}&SRS=EPSG:4326&WIDTH=${WIDTH}&HEIGHT=${HEIGHT}&BBOX=${BBOX}`;
      setPointValue(null);
      dispatch({
        type: geodataConstants.POINT_REQUEST,
        url: URL,
        setValue: setPointValue,
      });
      setPosition(e.latlng);
    }
  });

  const initMarker = (ref) => {
    if (ref) {
      ref.openPopup();
    }
  };

  return position === null ? null : (
    <Marker position={position} ref={initMarker}>
      <Popup>{pointValue ? `SOC is ${pointValue}` : 'No data'}</Popup>
    </Marker>
  );
}

function SampleMap({ layers }) {
  const classes = useStyles();

  const center = [50, 15];
  const params = {
    layers,
    transparent: true,
    format: 'image/png'
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Simple map viewer" />
      <Divider />
      <CardContent className={classes.container}>
        <MapContainer
          className={classes.map}
          center={center}
          zoom={5}
          scrollWheelZoom
        >
          <LayersControl position="topright">
            {leafletConstants.BASEMAPS.map((basemap) => (
              <LayersControl.BaseLayer key={basemap.name} name={basemap.name} checked={basemap.name === 'ESRI'}>
                <TileLayer
                  attribution={basemap.attribution}
                  url={basemap.url}
                />
              </LayersControl.BaseLayer>
            ))}
            <LayersControl.Overlay checked name="i-BEC data">
              <LayerGroup>
                <WMSTileLayer
                  url="https://geoserver.i-bec.org/geoserver/wms?"
                  params={params}
                />
                <Legend
                  grades={[0, 5, 15, 25, 35, 45]}
                  colors={['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026']}
                />
                <LocationMarker layer="worldsoils:SOC_mosaic_bavaria_CNN_NAs_tiled" />
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      </CardContent>
    </Card>
  );
}

export default SampleMap;
