import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import getInitials from 'src/utils/getInitials';
import { mongoDataConstants } from 'src/constants';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={2}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    value: index,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      minWidth: 400
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 500
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 600
    },
    position: 'relative',
    maxHeight: 500,
    overflowY: 'auto'
  },
  avatar: {
    backgroundColor: red[500]
  },
  list: {
    width: '100%'
  },
  photoAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: 10
  },
  gridList: {
    width: '100%'
  },
  table: {
    minWidth: 300,
  },
}));

const keys = [
  'physicalMeasurements',
  'chemicalMeasurements',
  'biologicalMeasurements',
  'areaDescriptions',
  'sensor'
];

// InfoWindow component
function InfoWindow(props) {
  const { point } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const hasAnyMeasurement = keys
    .map((key) => point.properties[key])
    .reduce((acc, arr) => acc || (arr !== undefined && arr.length > 0), false);

  const active = keys
    .map((key) => point.properties[key])
    .map((arr, index) => (arr !== undefined && arr.length > 0 ? index : null))
    .filter((element) => element !== null);

  const [value, setValue] = useState(hasAnyMeasurement ? active[0] : false);

  const downloadImage = (index, key) => {
    const path = point.properties.areaDescriptions[index][key];
    dispatch({
      type: mongoDataConstants.FDTOOL_MAKE_PHOTO_REQUEST,
      payload: {
        id: point._id.$oid,
        index,
        key,
        path: path.substring(path.lastIndexOf('/') + 1)
      }
    });
  };

  return (
    <div className={classes.root}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>{getInitials(point.user)}</Avatar>
        }
        title={`Σημείο: ${point.properties.name}, Χρήστης: ${point.user}`}
        subheader={`Ημερομηνία: ${point.properties.created}, Συντεταγμένες: ${point.geometry.coordinates}`}
      />
      <CardContent>
        {hasAnyMeasurement ? (
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="measurement-tabs"
            >
              {point.properties.physicalMeasurements && (
                <Tab label="ΦΥΣΙΚΕΣ ΙΔΙΟΤΗΤΕΣ" {...a11yProps(0)} />
              )}
              {point.properties.chemicalMeasurements && (
                <Tab label="ΧΗΜΙΚΕΣ ΙΔΙΟΤΗΤΕΣ" {...a11yProps(1)} />
              )}
              {point.properties.biologicalMeasurements && (
                <Tab label="ΒΙΟΛΟΓΙΚΕΣ ΙΔΙΟΤΗΤΕΣ" {...a11yProps(2)} />
              )}
              {point.properties.areaDescriptions && (
                <Tab label="ΠΕΡΙΓΡΑΦΕΣ" {...a11yProps(3)} />
              )}
              {point.properties.sensor && (
                <Tab label={point.properties.sensor} {...a11yProps(4)} />
              )}
            </Tabs>
          </AppBar>
        ) : (
          <Typography>Δεν υπάρχουν μετρήσεις για αυτό το σημείο.</Typography>
        )}
        {point.properties.physicalMeasurements && (
          <TabPanel value={value} index={0}>
            {point.properties.physicalMeasurements.map((measurement, idx) => (
              <Accordion key={idx}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}a-content`}
                  id={`panel${idx}a-header`}
                >
                  <Typography>{measurement.createdOn}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List className={classes.list}>
                    {Object.keys(measurement)
                      .filter((key) => key !== 'createdOn')
                      .map((key) => (
                        <ListItem key={key}>
                          <ListItemText
                            primary={key}
                            secondary={measurement[key]}
                          />
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        )}
        {point.properties.chemicalMeasurements && (
          <TabPanel value={value} index={1}>
            {point.properties.chemicalMeasurements.map((measurement, idx) => (
              <Accordion key={idx}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}b-content`}
                  id={`panel${idx}b-header`}
                >
                  <Typography>{measurement.createdOn}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ImageList
                    cellHeight={50}
                    className={classes.gridList}
                    cols={3}
                  >
                    {Object.keys(measurement)
                      .filter((key) => key !== 'createdOn')
                      .map((key, chemiId) => (
                        <ImageListItem key={key} cols={key === 'CaCO3' ? 2 : 1}>
                          <ListItemText
                            primary={key}
                            secondary={measurement[key]}
                          />
                        </ImageListItem>
                      ))}
                  </ImageList>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        )}
        {point.properties.biologicalMeasurements && (
          <TabPanel value={value} index={2}>
            {point.properties.biologicalMeasurements.map((measurement, idx) => (
              <Accordion key={idx}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}c-content`}
                  id={`panel${idx}c-header`}
                >
                  <Typography>{measurement.createdOn}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List className={classes.list}>
                    {Object.keys(measurement)
                      .filter((key) => key !== 'createdOn')
                      .map((key) => (
                        <ListItem key={key}>
                          <ListItemText
                            primary={key}
                            secondary={measurement[key]}
                          />
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        )}
        {point.properties.areaDescriptions && (
          <TabPanel value={value} index={3}>
            {point.properties.areaDescriptions.map((measurement, idx) => (
              <Accordion key={idx}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}d-content`}
                  id={`panel${idx}d-header`}
                >
                  <Typography>{measurement.createdOn}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List className={classes.list}>
                    {Object.keys(measurement)
                      .filter((key) => key !== 'createdOn')
                      .map((key) => (
                        <ListItem alignItems="center" key={key}>
                          {key.includes('Photo') ? (
                            <>
                              <ListItemAvatar>
                                {measurement[key].startsWith('fdtool.files') ? (
                                  <Avatar
                                    variant="rounded"
                                    className={classes.photoAvatar}
                                  >
                                    IMG
                                  </Avatar>
                                ) : (
                                  <Zoom>
                                    <Avatar
                                      alt="photo"
                                      src={`data:image/jpeg;base64,${measurement[key]}`}
                                      variant="rounded"
                                      className={classes.photoAvatar}
                                    />
                                  </Zoom>
                                )}
                              </ListItemAvatar>
                              <ListItemText primary={key} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  onClick={() => downloadImage(idx, key)}
                                  disabled={
                                    !measurement[key].startsWith('fdtool.files')
                                  }
                                  size="large"
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </>
                          ) : (
                            <ListItemText
                              primary={key}
                              secondary={measurement[key]}
                            />
                          )}
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        )}
        {point.properties.sensor && (
          <TabPanel value={value} index={4}>
            <Table className={classes.table} aria-label="insitu table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {point.properties.values.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.unit}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
        )}
      </CardContent>
    </div>
  );
}

export default InfoWindow;
