import {
  call, delay, put, race
} from 'redux-saga/effects';
import { geodataConstants } from 'src/constants';

const getRequest = async (url) => {
  const response = await fetch(`https://geoserver.i-bec.org/geoserver/${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then((result) => {
      if (!result.ok) {
        return { message: 'Result from GET is not ok' };
      }
      return result.json().then((res) => {
        console.log('Request OK!');
        return res;
      });
    })
    .catch((error) => {
      console.error('Error: ', error);
      return { message: error };
    });
  return response;
};

export function* getPointValue({ url, setValue }) {
  const raced = yield race({
    value: call(getRequest, url),
    connectionTimeout: delay(2000)
  });
  if (raced.value) {
    let num = raced.value.features[0].properties.SOC;
    num = num < 0 ? null : Math.round((num + Number.EPSILON) * 100) / 100;
    setValue(num);
  } else {
    yield put({
      type: geodataConstants.POINT_FAILURE,
      payload: {
        message: 'Connection timed out'
      }
    });
  }
}
