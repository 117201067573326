import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search as SearchIcon } from 'react-feather';
import { CsvBuilder } from 'filefy';
import { mongoDataConstants } from 'src/constants';
import ChemistryModal from './ChemistryModal';
import DeleteModal from './DeleteModal';
import config from '../../../config.json';

const useStyles = makeStyles((theme) => ({
  documentationLink: {
    marginLeft: theme.spacing(2)
  },
  toolbarButton: {
    marginRight: theme.spacing(2)
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none',
  }
}));

function Toolbar({
  className, SelectedIds, setOpen, ...rest
}) {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [isChemistryModalOpen, setChemistryModalOpen] = useState(
    false
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(
    false
  );
  const [hasSelectData, setSelectData] = useState([]);

  const dispatch = useDispatch();
  const chemistry = useSelector((state) => {
    return state.app.get('chemistry');
  });

  const search = () => {
    dispatch({
      type: mongoDataConstants.CHEMISTRY_DATA_MAKE_CUSTOM_REQUEST,
      payload: { searchString }
    });
  };

  const exportCsv = (header, data) => {
    const builder = new CsvBuilder('chemistry.csv');
    builder.setDelimeter(',').setColumns(header);
    for (const datum of data) {
      const array = [];
      header.forEach((val) => {
        if (datum.hasOwnProperty(val)) {
          array.push(datum[val]);
        } else if (val.includes('.')) {
          const prop = val.split('.');
          if (datum.values.hasOwnProperty(prop[0])) {
            array.push(datum.values[prop[0]][prop[1]]);
          } else {
            array.push('');
          }
        } else {
          array.push('');
        }
      });
      builder.addRow(array);
    }
    builder.exportFile();
  };

  const exportData = () => {
    const header = config.chemistry;
    const data = chemistry
      .get('data')
      .toJS()
      .filter((datum) => SelectedIds.includes(datum._id.$oid));
    console.log(data);
    exportCsv(header, data);
  };

  const deleteData = () => {
    const data = chemistry
      .get('data')
      .toJS()
      .filter((datum) => SelectedIds.includes(datum._id.$oid));
    setSelectData(data);
    setDeleteModalOpen(true);
  };

  const toggleCollapse = () => {
    setOpen((prevState) => prevState.map((item) => !item));
  };

  const collapseAll = () => {
    setOpen((prevState) => prevState.map((item) => false));
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.toolbarButton} onClick={collapseAll}>
          Collapse all
        </Button>
        <Button className={classes.toolbarButton} onClick={toggleCollapse}>
          Toggle collapse
        </Button>
        <Button
          className={classes.toolbarButton}
          color="primary"
          variant="contained"
          onClick={exportData}
          disabled={SelectedIds.length === 0}
        >
          Export
        </Button>
        <Button
          className={classes.toolbarButton}
          color="secondary"
          variant="contained"
          onClick={deleteData}
          disabled={SelectedIds.length === 0}
        >
          Delete
        </Button>
        <Button variant="contained" onClick={() => setChemistryModalOpen(true)}>
          Add new
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={600} display="flex" alignItems="center">
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search data with project name"
                variant="outlined"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <Button className={classes.searchButton} onClick={search}>
                Search
              </Button>
              <Typography>
                <Link
                  href="https://restheart.org/docs/read-docs/"
                  className={classes.documentationLink}
                  variant="inherit"
                >
                  Documentation
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <ChemistryModal
        open={isChemistryModalOpen}
        setOpen={setChemistryModalOpen}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
        select={hasSelectData}
        setSelect={setSelectData}
      />
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  SelectedIds: PropTypes.array,
  setOpen: PropTypes.func
};

export default Toolbar;
