import {
  call, delay, put, race
} from 'redux-saga/effects';
import { datacubeConstants } from 'src/constants';
import {
  setAreas,
  setDatasets,
  setDisk,
  setStatus,
  setQuickview
} from 'src/store/datacube/actions';

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.error('401');
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};

const getRequest = async (query) => {
  const response = await fetch(`./api/datacube/${query}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    }
  }).then(handleResponse);
  return response;
};

const postRequest = async (query, payload) => {
  const response = await fetch(`./api/datacube/${query}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    },
    body: JSON.stringify(payload)
  }).then(handleResponse);
  return response;
};

const putRequest = async (query, payload) => {
  const response = await fetch(`./api/datacube/${query}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    },
    body: JSON.stringify(payload)
  }).then(handleResponse);
  return response;
};

const deleteRequest = async (query) => {
  const response = await fetch(`./api/datacube/${query}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
    }
  }).then(handleResponse);
  return response;
};

export function* getDatacubeDatasets() {
  yield put({
    type: datacubeConstants.WAIT_DATASETS_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, 'datasets'),
      connectionTimeout: delay(30000)
    });

    yield put({
      type: datacubeConstants.WAIT_DATASETS_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put(setDatasets(raced.posted));
    } else {
      yield put({
        type: datacubeConstants.DATASETS_FAILURE,
        message:
          'Unable to load the indexed datasets. Connection timed out after 30 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.DATASETS_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    yield put({
      type: datacubeConstants.WAIT_DATASETS_REQUEST,
      payload: false
    });
  }
}

export function* getDatacubeQuickview({ id }) {
  yield put({
    type: datacubeConstants.WAIT_QUICKVIEW_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, `datasets/${id}/quickview`),
      connectionTimeout: delay(20000)
    });

    yield put({
      type: datacubeConstants.WAIT_QUICKVIEW_REQUEST,
      payload: false
    });

    if (raced.posted && raced.posted.image) {
      yield put(setQuickview(raced.posted.image));
    } else {
      yield put({
        type: datacubeConstants.QUICKVIEW_FAILURE,
        message:
          'Unable to load the quickview image. Connection timed out after 20 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.QUICKVIEW_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    yield put({
      type: datacubeConstants.WAIT_QUICKVIEW_REQUEST,
      payload: false
    });
  }
}

export function* getDatacubeAreas() {
  yield put({
    type: datacubeConstants.WAIT_AREAS_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, 'area'),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_AREAS_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put(setAreas(raced.posted));
    } else {
      yield put({
        type: datacubeConstants.AREAS_FAILURE,
        message:
          'Unable to load the configured areas. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_AREAS_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.AREAS_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  }
}

export function* createDatacubeArea({ payload, setOpen, showSnackbar }) {
  yield put({
    type: datacubeConstants.WAIT_AREA_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(postRequest, 'area', payload),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put({
        type: datacubeConstants.CREATE_AREA_SUCCESS,
        area: { ...payload, id: raced.posted.id },
        message: raced.posted.message
      });
    } else {
      yield put({
        type: datacubeConstants.CREATE_AREA_FAILURE,
        message:
          'Unable to create the new areas. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.CREATE_AREA_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    setOpen(false);
    showSnackbar(true);
  }
}

export function* deleteDatacubeArea({ payload, showSnackbar }) {
  yield put({
    type: datacubeConstants.WAIT_AREA_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      // posted: call(deleteRequest, `area/${payload}`),
      // connectionTimeout: delay(5000)
      posted: call(deleteRequest, `area/${payload}`),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put({
        type: datacubeConstants.DELETE_AREA_SUCCESS,
        data: payload,
        message: raced.posted.message
      });
    } else {
      yield put({
        type: datacubeConstants.DELETE_AREA_FAILURE,
        message:
          'Unable to delete the selected area. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.DELETE_AREA_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    showSnackbar(true);
  }
}

export function* editDatacubeArea({ payload, setOpen, showSnackbar }) {
  yield put({
    type: datacubeConstants.WAIT_AREA_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      // posted: call(putRequest, `area/${payload.id}`, payload),
      // connectionTimeout: delay(5000)
      posted: call(putRequest, `area/${payload.id}`, payload),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put({
        type: datacubeConstants.EDIT_AREA_SUCCESS,
        area: payload,
        message: raced.posted.message
      });
    } else {
      yield put({
        type: datacubeConstants.EDIT_AREA_FAILURE,
        message:
          'Unable to edit the selected area. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_AREA_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.EDIT_AREA_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    setOpen(false);
    showSnackbar(true);
  }
}

export function* deleteDatacubeDataset({ id, showSnackbar }) {
  yield put({
    type: datacubeConstants.WAIT_DATASETS_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(deleteRequest, `dataset/${id}`),
      connectionTimeout: delay(30000)
    });

    yield put({
      type: datacubeConstants.WAIT_DATASETS_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put({
        type: datacubeConstants.DELETE_DATASETS_SUCCESS,
        data: id,
        message: raced.posted.message
      });
    } else {
      yield put({
        type: datacubeConstants.DELETE_DATASETS_FAILURE,
        message:
          'Unable to delete the selected area. Connection timed out after 30 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_DATASETS_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.DELETE_DATASETS_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    showSnackbar(true);
  }
}

export function* getDiskUsage() {
  yield put({
    type: datacubeConstants.WAIT_DISK_USAGE_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, 'disk'),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_DISK_USAGE_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put(setDisk(raced.posted));
    } else {
      yield put({
        type: datacubeConstants.DISK_USAGE_FAILURE,
        message:
          'Unable to query disk usage. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_DISK_USAGE_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.DISK_USAGE_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  }
}

export function* getStatus() {
  yield put({
    type: datacubeConstants.WAIT_STATUS_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(getRequest, 'status'),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_STATUS_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put(setStatus(raced.posted));
    } else {
      yield put({
        type: datacubeConstants.STATUS_FAILURE,
        message:
          'Unable to query service status. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_STATUS_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.STATUS_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  }
}

export function* forceSync({ showSnackbar }) {
  yield put({
    type: datacubeConstants.WAIT_SYNC_REQUEST,
    payload: true
  });

  try {
    const raced = yield race({
      posted: call(postRequest, 'sync', {}),
      connectionTimeout: delay(10000)
    });

    yield put({
      type: datacubeConstants.WAIT_SYNC_REQUEST,
      payload: false
    });

    if (raced.posted) {
      yield put({
        type: datacubeConstants.SYNC_SUCCESS,
        message: raced.posted.message
      });
    } else {
      yield put({
        type: datacubeConstants.SYNC_FAILURE,
        message: 'Unable to force a sync. Connection timed out after 10 seconds.'
      });
    }
  } catch (error) {
    yield put({
      type: datacubeConstants.WAIT_SYNC_REQUEST,
      payload: false
    });
    yield put({
      type: datacubeConstants.SYNC_FAILURE,
      message: `An unexpected error ocurred: ${error}`
    });
  } finally {
    showSnackbar(true);
  }
}
