import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Page from 'src/components/Page';
import { mongoDataConstants } from 'src/constants';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function ChemistryListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [SelectedIds, setSelectedIds] = useState([]);
  const chemistry = useSelector((state) => {
    return state.app.get('chemistry');
  });
  const [open, setOpen] = useState(new Array(500).fill(false));
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: mongoDataConstants.CHEMISTRY_CLEAR_ALERT });
    setSnackbarOpen(false);
  };

  if (!snackbarOpen && chemistry.get('alert').get('message')) {
    setSnackbarOpen(true);
  }

  return (
    <Page className={classes.root} title="Chemistry">
      <Container maxWidth={false}>
        {chemistry.get('alert').get('severity') === 'error' ? (
          <Alert severity="error">{chemistry.get('alert').get('message')}</Alert>
        ) : (
          <>
            <Typography variant="h3">Chemistry</Typography>
            <Toolbar SelectedIds={SelectedIds} setOpen={setOpen} />
            <Box mt={3}>
              <Results
                SelectedIds={SelectedIds}
                setSelectedIds={setSelectedIds}
                open={open}
                setOpen={setOpen}
              />
            </Box>
            <Backdrop
              className={classes.backdrop}
              open={chemistry.get('loading') || chemistry.get('loadingCustomQuery')}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {chemistry.get('alert').get('severity') && (
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={chemistry.get('alert').get('severity')}
                >
                  {chemistry.get('alert').get('message')}
                </Alert>
              </Snackbar>
            )}
          </>
        )}
      </Container>
    </Page>
  );
}

export default ChemistryListView;
