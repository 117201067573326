import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

function getStyles(layer, layers, theme) {
  return {
    fontWeight:
      layers.indexOf(layer) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Toolbar({
  className, layers, selectedLayers, setSelectedLayers, ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event) => {
    setSelectedLayers(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center">
              <FormControl className={classes.formControl}>
                <InputLabel id="mutiple-chip-label">Layers</InputLabel>
                <Select
                  labelId="mutiple-chip-label"
                  id="mutiple-chip"
                  multiple
                  value={selectedLayers}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {layers.map((layer) => (
                    <MenuItem key={layer} value={layer} style={getStyles(layer, selectedLayers, theme)}>
                      {layer}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  SelectedIds: PropTypes.array,
  setOpen: PropTypes.func
};

export default Toolbar;
