import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import SampleMap from './SampleMap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const layers = [
  'ag-cluster:Imathia',
  'worldsoils:SOC_mosaic_bavaria_CNN_NAs_tiled'
];

function GeodataView() {
  const classes = useStyles();
  const error = useSelector((state) => state.datacube.get('error'));

  const [selectedLayers, setSelectedLayers] = React.useState(['worldsoils:SOC_mosaic_bavaria_CNN_NAs_tiled']);

  return (
    <Page className={classes.root} title="Geodata viewer">
      <Container maxWidth={false}>
        {error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Grid container mt={2} spacing={3}>
            <Grid item xs={12}>
              <Toolbar
                layers={layers}
                selectedLayers={selectedLayers}
                setSelectedLayers={setSelectedLayers}
              />
            </Grid>
            <Grid item xs={12}>
              <SampleMap layers={selectedLayers} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}

export default GeodataView;
