import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableContainer,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import 'react-medium-image-zoom/dist/styles.css';

function Values({ object }) {
  const { values } = object;

  return (
    <Box margin={1}>
      <Grid container spacing={3}>
        <Grid item>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Method</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(values).map((key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="right">{values[key]?.Value}</TableCell>
                    <TableCell>{values[key]?.Unit}</TableCell>
                    <TableCell>{values[key]?.Method}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

Values.propTypes = {
  object: PropTypes.object.isRequired
};

export default Values;
