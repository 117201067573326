import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { parse } from 'terraformer-wkt-parser';
import { datacubeConstants } from 'src/constants';
import { parseISO } from 'date-fns';

function AreaForm({
  title, open, setOpen, showSnackbar, area
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  if (area.start_date) {
    area.start_date = moment(area.start_date, 'YYYYMMDD').toISOString();
  } else {
    area.start_date = null;
  }
  if (area.end_date) {
    area.end_date = moment(area.end_date, 'YYYYMMDD').toISOString();
  } else {
    area.end_date = null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={area}
        validationSchema={Yup.object().shape({
          description: Yup.string().min(5, 'Must be at least 5 characters'),
          area: Yup.string()
            .test(
              'valid-wkt',
              'This text is not in a valid WKT format',
              (value) => {
                try {
                  parse(value);
                  return true;
                } catch (e) {
                  return false;
                }
              }
            )
            .required('Area is required'),
          start_date: Yup.date().nullable(),
          end_date: Yup.date().nullable()
        })}
        onSubmit={(obj, { setSubmitting }) => {
          // remove null values
          obj = Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => v != null)
          );
          // ensure date string format is proper
          if (obj.start_date) {
            obj.start_date = moment(obj.start_date).format('YYYY-MM-DD');
          }
          if (obj.end_date) {
            obj.end_date = moment(obj.end_date).format('YYYY-MM-DD');
          }
          const reqType = obj.id
            ? datacubeConstants.EDIT_AREA_REQUEST
            : datacubeConstants.CREATE_AREA_REQUEST;
          dispatch({
            type: reqType,
            payload: obj,
            setOpen,
            showSnackbar
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>Please fill the form below.</DialogContentText>
              <Container maxWidth="sm">
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label="Description in physical language"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.area && errors.area)}
                  fullWidth
                  helperText={touched.area && errors.area}
                  label="WKT description"
                  margin="normal"
                  name="area"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.area}
                  variant="outlined"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    slotProps={{ textField: { helperText: 'Leave empty for default start date', variant: 'outlined' } }}
                    margin="normal"
                    id="start-date-picker-inline"
                    label="Start date"
                    value={parseISO(values.start_date)}
                    onChange={(val) => {
                      setFieldValue('start_date', val);
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    slotProps={{ textField: { helperText: 'Leave empty to continuously monitor', variant: 'outlined' } }}
                    margin="normal"
                    id="end-date-picker-inline"
                    label="End date"
                    value={parseISO(values.end_date)}
                    onChange={(val) => {
                      setFieldValue('end_date', val);
                    }}
                  />
                </LocalizationProvider>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

AreaForm.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  area: PropTypes.object
};

AreaForm.defaultProps = {
  area: {
    description: '', area: '', start_date: null, end_date: null
  }
};

export default AreaForm;
