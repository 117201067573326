import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { userConstants } from 'src/constants';

function AlertDialog({
  open, setOpen, user, id, active
}) {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch({
      type: userConstants.UPDATE_REQUEST,
      payload: { id, active: !active }
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Θέλετε να ${
          active ? 'απενεργοποιήσετε' : 'ενεργοποιήσετε'
        } τον χρήστη ${
          user
        };`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ένας ενεργός χρήστης μπορεί να συνδεθεί στην πλατφόρμα.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          ΑΚΥΡΟ
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          ΝΑΙ, ΕΙΜΑΙ ΣΙΓΟΥΡΟΣ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  user: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool
};

export default AlertDialog;
