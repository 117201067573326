import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  MapContainer, TileLayer, Marker, Popup, LayersControl
} from 'react-leaflet';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';

const useStyles = makeStyles((theme) => ({
  root: {},
  leafletContainer: {
    height: '800px'
  }
}));

const basemaps = [
  {
    name: 'ESRI',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
];

function Map() {
  const classes = useStyles();

  const mems = useSelector((state) => {
    return state.app.get('mems');
  });
  const [map, setMap] = useState({
    data: null,
    center: [54.54, 25.19],
    zoom: 4
  });

  useEffect(() => {
    if (!mems.get('loading')) {
      setMap((prevState) => ({ ...prevState, data: mems.get('data').toJS() }));
    }
  }, [mems.get('data')]);

  return map.data !== null ? (
    <MapContainer center={map.center} zoom={map.zoom} scrollWheelZoom={false} className={classes.leafletContainer}>
      <LayersControl position="topright">
        {basemaps.map((basemap) => (
          <LayersControl.BaseLayer key={basemap.name} name={basemap.name} checked={basemap.name === 'ESRI'}>
            <TileLayer
              attribution={basemap.attribution}
              url={basemap.url}
            />
          </LayersControl.BaseLayer>
        ))}
      </LayersControl>
      <MarkerClusterGroup>
        {map.data.map((val) => (
          <Marker key={val._id.$oid} position={[val.location.coords.latitude, val.location.coords.longitude]}>
            <Popup>
              <b>{(val.sample && val.sample.value) ? val.sample.value : 'Null'}</b>
              <br />
              {val.user && val.user.familyName && `User: ${val.user.familyName}`}
              <br />
              {val.date && val.date.date && `Date: ${val.date.date}`}
              <br />
              {val.analysis && val.analysis.SOC && `SOC: ${parseFloat(val.analysis.SOC).toFixed(2)} %` }
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  ) : null;
}

export default Map;
