import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  success: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  failure: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function ServiceStatus({
  active, lastSync, nextSync, makeSync
}) {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Service status
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Last auto sync:
              {' '}
              {lastSync}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={active ? classes.success : classes.failure}>
              {active ? <CheckCircleOutlineOutlinedIcon /> : <ErrorOutlineOutlinedIcon />}
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Next sync scheduled on:
            {' '}
            {nextSync}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" onClick={makeSync}>Force sync now</Button>
      </CardActions>
    </Card>
  );
}

ServiceStatus.propTypes = {
  active: PropTypes.bool.isRequired,
  lastSync: PropTypes.string.isRequired,
  nextSync: PropTypes.string.isRequired,
  makeSync: PropTypes.func.isRequired,
};

export default ServiceStatus;
