import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import {
  BarChart as BarChartIcon,
  Box as BoxIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon,
  Database as DatabaseIcon,
  MapPin as MapPinIcon,
  Map as MapIcon,
  Archive as ChemistryIcon
} from 'react-feather';
import NavItem from './NavItem';

let items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/fdtool',
    icon: MapPinIcon,
    title: 'FDTool'
  },
  {
    href: '/app/mems',
    icon: DatabaseIcon,
    title: 'MEMS spectra'
  },
  {
    href: '/app/chemistry',
    icon: ChemistryIcon,
    title: 'Chemistry'
  },
  {
    href: '/app/datacube',
    icon: BoxIcon,
    title: 'Datacube'
  },
  {
    href: '/app/geodata',
    icon: MapIcon,
    title: 'Geodata'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ onMobileClose, openMobile, logOut }) {
  const classes = useStyles();
  const location = useLocation();

  const user = useSelector((state) => {
    return state.app.get('user');
  });

  if (user.get('role') !== 'Administrator') {
    items = items.filter((obj) => obj.title !== 'Customers' && obj.title !== 'Datacube' && obj.title !== 'chemistry');
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={`data:image/${user.get('avatar_ext')};base64,${user.get('avatar_base64')}`}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.get('name')}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.get('email')}
        </Typography>
        <Hidden lgUp>
          <Button color="primary" onClick={logOut}>
            Log out
          </Button>
        </Hidden>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              active={item.href === location.pathname}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  logOut: PropTypes.func
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
  logOut: () => {}
};

export default NavBar;
