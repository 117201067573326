import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box, Button, Container, TextField, Typography,
  Alert, AlertTitle
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'src/components/Page';
import { alertConstants, userConstants } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function ResetView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.get('user'));
  const alert = useSelector((state) => state.app.get('alert'));
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { token } = useParams();

  useEffect(() => {
    dispatch({ type: alertConstants.CLEAR });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) return <Navigate to="/app/dashboard" />;

  return (
    <Page className={classes.root} title="Password reset">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, 'Password should be at least 8 chars long')
                .max(30, 'Maximum password length is 30')
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^@$!%*?&])[A-Za-z\d#^@$!%*?&]{8,}$/,
                  'Password must contain at least one lowercase and one uppercase character, one number and one special character'
                )
                .required('Password is required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please re-type your password')
            })}
            onSubmit={(v, { setSubmitting }) => {
              dispatch({
                type: userConstants.RESET_REQUEST,
                payload: { token, password: v.password, setSubmitting }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Reset your password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    This view allows you to enter a new password for your account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Re-type password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset my password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          {alert.get('message') && (
            <Box mt={2}>
              <Alert severity={alert.get('severity')}>
                <AlertTitle>{capitalize(alert.get('severity'))}</AlertTitle>
                {alert.get('severity') === 'error' ? (
                  <>
                    Something went wrong! —
                    {' '}
                    <strong>{alert.get('message')}</strong>
                    {' '}
                  </>
                ) : (
                  <>{alert.get('message')}</>
                )}
              </Alert>
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
}

export default ResetView;
